import React, { useEffect } from 'react';
import ProductUI from "../../components/admin/product/components/Product";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
import { jwtDecode } from "jwt-decode";
import { WEB_BASE_URL } from '../../constants';

const Product = () => {
  const token:string = localStorage.getItem("token") ? ""+localStorage.getItem("token")+"" : "";
  const decoded:{id: number; role: string;} = jwtDecode(token);
  useEffect(()=>{
    if(decoded.role !== "ADMIN" ) {
      window.location.href = WEB_BASE_URL + "admin/quotation/create";
     }
  },[]);
  
  return (
    decoded.role === "ADMIN" ?
    <div>
    <PersistentDrawerLeft>
      <ProductUI/>
   </PersistentDrawerLeft>
    </div> : null
  );
};

export default Product;