import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Container, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  Select, MenuItem, TableRow, Button, InputLabel, FormControl, Box
} from '@mui/material';
import { borderTop, styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { order_detail } from '../services/order';
import * as XLSX from 'xlsx-js-style';
import UniqueBiologicalHeader from '../../../reusable/UniqueBiologicalHeader';
import CompanyDepartmentDropdown from '../../../reusable/CompanyDepartmentDropdown';
import Termsconditions from '../../../reusable/Termsconditions';
import { Link } from 'react-router-dom';
import { WidthFull } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Col, Row } from 'antd';
import SohamHeader from '../../../reusable/SohamHeader';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledContainer = styled(Container)({
  marginTop: 20,
  paddingBottom: 20,
});

const StyledHeader = styled(Paper)({
  padding: 20,
  marginBottom: 20,
  textAlign: 'center',
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: 20,
});

const StyledButton = styled(Button)({
  marginTop: 20,
  backgroundColor: 'rgb(100, 113, 60)',
  color: '#fff',
});

const OrderDetailPage = () => {
  const [openInvoiceDailog, setOpenInvoiceDailog] = React.useState(false);
  const [selectedDepartments, setSelectedDepartmentss] = useState<any>(null);
  const [selectedCompany, setSelectedCompanyy] = useState<any>(null);
  const [selecteduitable, setSelecteduitablee] = useState<any>("UNIQUE FIRM");
  const { orderId } = useParams();
  const [order, setOrder] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [companies,setCompanies] = useState([]);
  const [orderProducts, setOrderProducts] = useState<any[]>([]);
  const [printexl, setprintexl] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    isError: false
  });
  // console.log("selecteduitable", selecteduitable);
  const printRef = useRef<HTMLDivElement>(null); // ref for print section
  const printRef2 = useRef<HTMLDivElement>(null); // ref for print section

  useEffect(() => {
    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);
  const fetchOrderDetail = async () => {
    const response: any = await order_detail(orderId);
    setOrder(response?.data?.quotation || {});
    setData(response?.data?.quotationProducts)
    setCompanies(response?.data?.companies && response?.data?.companies.length > 0 ?response?.data?.companies:[])
    const transformedData = response?.data?.quotationProducts?.map((item: any, index: any) => {
      return {
        id: index + 1,
        name: item.productName,
        attribute: {
          "id": item.id,
          "quotationId": item.quotationId,
          "userId": item.userId,
          "qty": item.qty,
          "productId": item.productId,
          "companyId": item.companyId,
          "price": item.price,
          "discount": item.discount,
          "discountValue": item.discountValue,
          "discountAmount": item.discountAmount,
          "gstAmount": item.gstAmount,
          "totalPrice": item.totalPrice,
          "createdDttm": item.createdDttm,
          "gst_percentage": item.gst_percentage,
          "companyProductCode": item.companyProductCode,
          "gstValue": item.gstValue,
          "quotationQty": item.quotationQty,
          "productName": item.productName,
          "subCategoryName": item.subCategoryName,
          "categoryName": item.categoryName,
          "companyName": item.companyName,
          "unitName": item.unitName
        },
        quotationQty: item.quotationQty
      }
    }) || [];
    setOrderProducts([...transformedData]);
  };
  // console.log("selectedDepartments", selectedDepartments);
  // Helper function to find the rate, discount, and final amount
  const findRates = (item: any, companyName: any) => ({
    [`${companyName}Rate`]: item.price,
    [`${companyName}DiscountPercent`]: item.discount,
    [`${companyName}DiscountAmt`]: item.discountAmount,
    [`${companyName}Gst`]: item.gst_percentage, // Using gst_percentage
    [`${companyName}Qty`]: item.quotationQty,
    [`${companyName}FinalAmt`]: item.totalPrice,
  });

  function transformData(data: any) {
    const result: any = [];

    // Grouping data by productId, unitName, and qty
    const groupedData = data.reduce((acc: any, item: any) => {
      const key = `${item.productId}-${item.unitName}-${item.qty}`;
      if (!acc[key]) {
        acc[key] = {
          id: item.productId,
          productName: item.productName,
          pack: `${item.qty} ${item.unitName}`,
          gst: item.gst_percentage, // Assuming GST percentage is the same for all companies
          ...findRates(item, item.companyName.toLowerCase())
        };
      } else {
        Object.assign(acc[key], findRates(item, item.companyName.toLowerCase()));
      }
      return acc;
    }, {});

    // Convert grouped data to the result array
    Object.values(groupedData).forEach((item: any, index: any) => {
      result.push({
        id: index + 1,
        ...item
      });
    });

    return result;
  }
  // function generateCSV(dataa: any) {
  //   let data = transformData(dataa);
  //   // CSV header
  //   let csv = "No,Name of Chemicals, , Pack, , , rate,, , Dis.%, , , Dis.Amt, ,, GST,, , Final Amount, ,  \n";
  //   csv += ", ,PCL , RLCC, LOBA,PCL , RLCC, LOBA,PCL , RLCC, LOBA,PCL , RLCC, LOBA, PCL , RLCC, LOBA, PCL , RLCC, LOBA  \n";
  //   // Process each product
  //   data.forEach((item: any, index: any) => {
  //     let row = [
  //       index + 1,
  //       item.productName,
  //       item.pclRate ? item.pack : "-",
  //       item.rlccRate ? item.pack : "-",
  //       item.lobaRate ? item.pack : "-",
  //       item.pclRate ? item.pclRate : 0,
  //       item.rlccRate ? item.rlccRate : 0,
  //       item.lobaRate ? item.lobaRate : 0,
  //       item.pclDiscountPercent ? item.pclDiscountPercent : 0 + '%',
  //       item.rlccDiscountPercent ? item.rlccDiscountPercent : 0 + '%',
  //       item.lobaDiscountPercent ? item.lobaDiscountPercent : 0 + '%',
  //       item.pclDiscountAmt ? item.pclDiscountAmt : 0,
  //       item.rlccDiscountAmt ? item.rlccDiscountAmt : 0,
  //       item.lobaDiscountAmt ? item.lobaDiscountAmt : 0,
  //       (item.pclGst ? item.pclGst : 0) + '%',
  //       (item.rlccGst ? item.rlccGst : 0) + '%',
  //       (item.lobaGst ? item.lobaGst : 0) + '%',
  //       item.pclFinalAmt ? item.pclFinalAmt : 0,
  //       item.rlccFinalAmt ? item.rlccFinalAmt : 0,
  //       item.lobaFinalAmt ? item.lobaFinalAmt : 0
  //     ].join(",");

  //     csv += row + "\n";
  //   });

  //   // Trigger download of the CSV file
  //   let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //   let link = document.createElement("a");
  //   let url = URL.createObjectURL(blob);
  //   link.setAttribute("href", url);
  //   link.setAttribute("download", "chemical_data.csv");
  //   link.style.visibility = 'hidden';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  function generateExcel(dataa: any) {
    let finalData = dataa;
    if(selectedCompany && selectedCompany.length > 0){
      finalData = dataa.filter((product:any) =>
          selectedCompany.includes(product.companyId)
      )
    }
    let data = transformData(finalData);
    setprintexl(data);
    let finalCompanies = companies;
    if(selectedCompany && selectedCompany.length > 0){
      finalCompanies = companies.filter((c:any) =>
          selectedCompany.includes(c.id)
      )
    }
    const mainHeader = selecteduitable === 'SOHAM FIRM' ?
      "Soham Chemicals" :
      " UNIQUE BIOLOGICAL & CHEMICALS";
    // const logoPath = 'https://i.pinimg.com/736x/da/c7/58/dac758d7606690d057a9cbba5f259154.jpg';
    const addressLine = selecteduitable === 'SOHAM FIRM' ? "📍 1330, Plot No.190, Jivhala Colony, Kolhapur - 416 010" : " 📍 726/A, 'E' Ward, Sai Plaza, 3rd Lane, Shahupuri, Kolhapur - 416001."
    const contactLine1 = selecteduitable === 'SOHAM FIRM' ? "📞 9730283030 / 8686648282," : "📞 0231-2661216, 📲 8686648282, 9922805555"
    const contactLine2 = selecteduitable === 'SOHAM FIRM' ? "✉️ sohamsci2005@yahoo.com, GST : 27CHIPP7362K1Z0" : "📞 9730283030, ✉️ vinodunique@yahoo.com, GST: 27AANPP2410Q1ZJ"

    // Create the headers
    // const header1 = ["No", "Name of Chemicals", "", "Pack", "", "", "Rate", "", "", "Dis.%", "", "", "Dis. Amt", "", "", "GST", "", "", "Final Amount", "", ""];
    // const header2 = ["", "", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA", "PCL", "RLCC", "LOBA"];
    // Initialize the headers
    const header1 = ['No', 'Name of Chemicals'];
    const header2 = ['', ''];
    
    // Define sections based on the requirements
    const sections = ['Pack', 'Rate', 'Dis.%', 'Dis. Amt', 'GST',
       'Qty',
       'Final Amount'];
    function getCenterIndex(arr:any) {
      if (arr.length === 0) return -1; // handle empty array
      return Math.floor(arr.length / 2);
    }
    let arrayCenterIndex = getCenterIndex(finalCompanies);
    console.log('-------', arrayCenterIndex);
    // Generate headers dynamically
    sections.forEach(section => {
      finalCompanies.forEach((company:any, index) => {
        console.log(index);
        if (arrayCenterIndex == index) {
          header1.push(section);
        } else {
          header1.push('');
        }
    
        header2.push(company.name);
      });
    });
    // Create rows array with bold headers
    const rows = [ 
      [mainHeader],             // Company Name Row
    // [logoPath],             // Company logo
    [addressLine],            // Address Row
    [contactLine1],           // Contact Line 1 Row
    [contactLine2],           // Contact Line 2 Row
    [],              
    header1, header2];

    // Process each product and create row data
    // data.forEach((item: any, index: any) => {
    //   const row = [
    //     index + 1,
    //     item.productName,
    //     item.pclRate ? item.pack : "-",
    //     item.rlccRate ? item.pack : "-",
    //     item.lobaRate ? item.pack : "-",
    //     item.pclRate ? item.pclRate : 0,
    //     item.rlccRate ? item.rlccRate : 0,
    //     item.lobaRate ? item.lobaRate : 0,
    //     item.pclDiscountPercent ? item.pclDiscountPercent + '%' : 0,
    //     item.rlccDiscountPercent ? item.rlccDiscountPercent + '%' : 0,
    //     item.lobaDiscountPercent ? item.lobaDiscountPercent + '%' : 0,
    //     item.pclDiscountAmt ? item.pclDiscountAmt : 0,
    //     item.rlccDiscountAmt ? item.rlccDiscountAmt : 0,
    //     item.lobaDiscountAmt ? item.lobaDiscountAmt : 0,
    //     item.pclGst ? item.pclGst + '%' : 0 + "%",
    //     item.rlccGst ? item.rlccGst + '%' : 0 + "%",
    //     item.lobaGst ? item.lobaGst + '%' : 0 + "%",
    //     item.pclFinalAmt ? item.pclFinalAmt : 0,
    //     item.rlccFinalAmt ? item.rlccFinalAmt : 0,
    //     item.lobaFinalAmt ? item.lobaFinalAmt : 0
    //   ];

    //   rows.push(row);
    // });
    console.log("data",data)
    data.map((item:any, index:any) => {
      // Initialize the row with basic information (index and product name)
      const row = [index + 1, item.productName];
  
      // Loop through each company in the `companies` array to extract values
      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase(); // Convert company name to lowercase
        row.push(item[`${companyKey}Rate`] ? item.pack : "-"); // Pack
      });
  
      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}Rate`] || 0); // Rate
      });
  
      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}DiscountPercent`] ? `${item[`${companyKey}DiscountPercent`]}%` : '0%'); // Discount Percent
      });
  
      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}DiscountAmt`] || 0); // Discount Amount
      });
  
      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}Gst`] ? `${item[`${companyKey}Gst`]}%` : '0%'); // GST
      });

      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}Qty`] ? `${item[`${companyKey}Qty`]}` : '0'); // Qty
      });
  
      companies.filter((c:any) =>
        selectedCompany ? (
          selectedCompany.includes(c.id) 
        ) : true
      ).forEach((company:any) => {
        const companyKey = company.name.toLowerCase();
        row.push(item[`${companyKey}FinalAmt`] || 0); // Final Amount
      });
      rows.push(row);
      return row;
    });

    
    // Create a new workbook and a new worksheet
    const ws = XLSX.utils.aoa_to_sheet(rows);

    // Apply bold styling to the header rows
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < header1.length; j++) {
        const cell = ws[XLSX.utils.encode_cell({ r: i, c: j })];
        if (cell && (i == 6 || i == 5 || i == 0)) {
          cell.s = {
            font: {
              bold: true
            }
          };
        }
      }
    }

    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Chemical Data');

    // Trigger download of the Excel file
    XLSX.writeFile(wb, 'chemical_data.xlsx');
  }

  const styles = {
    tableHead: {
      backgroundColor: selecteduitable == "SOHAM FIRM" ? "rgb(166, 166, 192)": 'rgb(155 177 90 / 80%)',
    },
    sohamtableHead: {
      backgroundColor: 'rgb(166 166 192)',
    },
    tableCell: {
      color: 'white',
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      fontWeight: 'bold',
    },
    tableCell2: {
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      // borderLeft: '0px solid rgb(100, 113, 60)',
      borderRight: '0px solid white',
      borderTop: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      // borderBottom: '1px solid rgb(100, 113, 60)',
      fontSize: '13px'
    },
    tableCell5: {
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderTop: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderRight: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)": '2px solid rgb(155 177 90 / 80%)',
      // borderBottom: '1px solid rgb(100, 113, 60)',
      fontSize: '13px'
    },
    tableCell6: {
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderTop: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderLeft: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)": '2px solid rgb(155 177 90 / 80%)',
      borderRight:  '0px solid rgb(155 177 90 / 80%)',
      fontSize: '13px'
    },
    tableCell7: {
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderTop: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderLeft: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)": '2px solid rgb(155 177 90 / 80%)',
      borderRight: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderBottom: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)": '2px solid rgb(155 177 90 / 80%)',
      fontSize: '13px'
    },
    tableCell8: {
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderTop: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      borderLeft: selecteduitable == "SOHAM FIRM" ? "0px solid rgb(166, 166, 192)": '0px solid rgb(155 177 90 / 80%)',
      borderRight: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)": '2px solid rgb(155 177 90 / 80%)',
      borderBottom: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)": '2px solid rgb(155 177 90 / 80%)',
      fontSize: '13px'
    },
    tableCell4: {
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)',
      fontSize: '16px',
      fontWeight: 'bold'
    },
    tableCell3: {
      width: '400px'
    },
    tableCellexl: {
      width: '1000px'
    },
    tableRow: {
      border:selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)": '1px solid rgb(155 177 90 / 80%)'
    },
    tablemain: {
      borderCollapse: 'separate' as 'collapse' | 'separate',
      width: '100%',
      tableLayout: 'auto' as 'auto', //| 'fixed',
      // border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)" : '1px solid rgb(155 177 90 / 80%)',
      // borderRight: selecteduitable == "SOHAM FIRM" ? "2px solid rgb(166, 166, 192)" : '2px solid rgb(155 177 90 / 80%)'
    },

  };

  // Print styles defined separately
  const PrintStyles = styled('div')(({ theme }) => ({
    '@media print': {
      width: '100%',
      margin: '0 auto',
      padding: '0',
      fontSize: '12px',
      // Add any additional print-specific styles here
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
      },
      '& td, & th': {
        padding: '8px',
        border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)" : '1px solid rgb(155 177 90 / 80%)',
      },
      '& .product-code-cell': {
        width: '300px !important',
      },
    },
  }));
  const generateNewForm = (dataa: any) => {
    let finalData = dataa;
    if(selectedCompany && selectedCompany.length > 0){
      finalData = dataa.filter((product:any) =>
          selectedCompany.includes(product.companyId)
      )
    }
    let data = transformData(finalData);
    setprintexl(data);
    setSnackbarOpen(true);
  }
  // console.log("printexl", printexl);
  // const filteredProducts = selectedCompany === 'ALL' ? orderProducts : orderProducts.filter(product =>
  //   selectedCompany === null || selectedCompany === product.attribute.totalPrice || selectedCompany === '' || product.attribute.companyName === selectedCompany
  // );
  const columnGroups = [
    { label: "Pack", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 },
    { label: "Rate", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 },
    { label: "Dis. %", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 },
    { label: "Dis. Amt", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 },
    { label: "GST", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 },
    { label: "Qty", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 },
    { label: "Final Amount", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
      selectedCompany ? (
        selectedCompany.includes(c.id) 
      ) : true
    ).length:3 }
  ];
  
  const filteredProducts = (selectedCompany && selectedCompany.includes('All'))
    ? orderProducts
    : orderProducts.filter(product =>
      selectedCompany ? (
        selectedCompany.includes(product.attribute.companyId) ||
        selectedCompany.includes(product.attribute.totalPrice)
      ) : true
    );

  const filteredprintexl = printexl; // selectedCompany === 'ALL' ?  printexl : printexl.filter(product =>
  //   selectedCompany === null || selectedCompany === '' || product.attribute.companyName === selectedCompany
  // );
  const totalAmount = filteredProducts.reduce((acc, product) => acc + product.attribute.totalPrice, 0).toFixed(2);
  // const exltotalAmount = filteredprintexl.reduce((acc, product) => acc + product.pclFinalAmt + product.rlccFinalAmt + product.lobaFinalAmt, 0).toFixed(2);
  const roundedTotalAmount = parseFloat(totalAmount);
  // const exlroundedTotalAmount = parseFloat(exltotalAmount);
  return (
    <>
      <StyledContainer >
        <Container>
          <CompanyDepartmentDropdown
          companies={companies}
            setSelectedDepartmentss={setSelectedDepartmentss} setSelectedCompanyy={setSelectedCompanyy} setSelecteduitablee={setSelecteduitablee} />
          <Grid container justifyContent="center" mt={5}>
            <Link to="/admin/quotation_list">
              <StyledButton variant="contained" >
                Quotation List
              </StyledButton>
            </Link>
            &nbsp;&nbsp;
            <ReactToPrint
              trigger={() => (
                <StyledButton variant="contained">
                  View-1 Print
                </StyledButton>
              )}
              content={() => printRef.current}
              pageStyle={`
            @page {
              margin-top: 10mm; /* Set all margins to 0 for print */
              padding: 0mm; /* Set padding to 0 for print */
              page-break-after: always; /* Ensure page breaks are respected */
            }
            .MuiContainer-root {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
            .logo2{
              width: 230px!important;
              height: 59px!important;
            }
            .logo1 {
              width: 190px !important;
              height: 69px !important;
              margin-bottom: -11px !important;
            }
            @media print {
              .product-code-cell-code {
                display: none !important; /* Ensure it displays correctly in print */
              }
            }
              th.product-code-cell, td.product-code-cell{
                width:175px !important ;
                max-width: 175px !important;
                white-space: normal !important;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
               
              }
          `}
            // th.product-code-cell, td.product-code-cell {
            //   width: 200px !important;
            //   max-width: 200px !important;
            //   min-width: 200px !important;
            //   word-break: break-word;
            // }
            // .header-logo {
            //   width: 10px;
            //   height: 10px;
            // }
            />
            &nbsp;&nbsp;
            <StyledButton variant="contained" onClick={() => generateExcel(data)}>
              Download Quotation (Excel) View-2
            </StyledButton>
            &nbsp;&nbsp;
            <StyledButton variant="contained" onClick={() => generateNewForm(data)}>
              Create pdf View-2
            </StyledButton>
            &nbsp;&nbsp;
            <ReactToPrint
              trigger={() => (
                <StyledButton variant="contained">
                  View-2 Print
                </StyledButton>
              )}
              content={() => printRef2.current}
              pageStyle={`
                        @page {
                          margin-top: 10mm; /* Set all margins to 0 for print */
                          padding: 0mm; /* Set padding to 0 for print */
                          page-break-after: always; /* Ensure page breaks are respected */
                        }
                        .MuiContainer-root {
                          padding-left: 0 !important;
                          padding-right: 0 !important;
                        }
                        
                        .logo2{
                          width: 307px!important;
                          height: 59px!important;
                        }
                        .logo1 {
                          width: 190px !important;
                          height: 69px !important;
                          margin-bottom: -11px !important;
                        }
                        .logo2{
                          width: 230px!important;
                          height: 59px!important;
                        }
                          th.product-code-cell, td.product-code-cell{
                          width:175px !important ;
                          max-width: 175px !important;
                          white-space: normal !important;
                          overflow: hidden !important;
                          text-overflow: ellipsis !important;
                        
                        }
                        @media print {
                          /* Hide overflow and scroll for print */
                          .product-code-cell-code {
                            display: table !important; /* Ensure table content shows */
                            overflow: hidden !important;
                            max-height: none !important;
                          }
                          /* Additional rule to remove scrollbars */
                          .product-code-cell-code::-webkit-scrollbar {
                            display: none;
                          }
                        }
                      `}
            // width: 230px !important;
            // height: 130px !important;
            />

          </Grid>
        </Container>
        <PrintStyles ref={printRef}>
          {selecteduitable === 'UNIQUE FIRM' && (
            <Container>
              <UniqueBiologicalHeader width={100} selecteduitable={selecteduitable}  order={order} />
            </Container>
          )}
          {selecteduitable === 'SOHAM FIRM' && (
            <Container>
              <SohamHeader width={100} selecteduitable={selecteduitable}  order={order} />
            </Container>
          )}

          <StyledTableContainer>
            <Container>
              {
                selectedDepartments && selectedDepartments.length > 0 &&
                selectedDepartments.map((e: any) => {

                  return (
                    <>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                          <label style={{ textAlign: 'center', fontSize: '19px', fontWeight: 'bold' }}>{e}</label>
                        </Col>
                      </Row>
                      <Table style={styles.tablemain} size={"small"}>
                      <TableBody >
                  <TableRow style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                    <TableCell style={styles.tableCell}>Sr. No.</TableCell>
                    <TableCell className="product-code-cell"
                     style={{ ...styles.tableCell, ...styles.tableCell3,
                 width: '200px',
                 maxWidth: '200px',
                 whiteSpace: "normal", // This ensures text does not overflow
                 overflow: 'hidden',
                 textOverflow: 'ellipsis'

                     }} >Product Code Name</TableCell>
                    <TableCell style={styles.tableCell}>Company</TableCell>
                    <TableCell style={styles.tableCell}>Pack</TableCell>
                    <TableCell style={styles.tableCell}>List Price (Rs.)</TableCell>
                    <TableCell style={styles.tableCell}>Disc (%)</TableCell>
                    <TableCell style={styles.tableCell}>Disc Price (Rs.)</TableCell>
                    <TableCell style={styles.tableCell}>GST (%)</TableCell>
                    <TableCell style={styles.tableCell}>GST Price (Rs.)</TableCell>
                    <TableCell style={styles.tableCell}>Qty</TableCell>
                    <TableCell style={styles.tableCell}>Total</TableCell>
                  </TableRow>
                  {filteredProducts.map((product: any, index: number) => {
                    return (
                      <TableRow key={product.id} style={styles.tableRow} >
                        <TableCell style={styles.tableCell6}>{index + 1}</TableCell>
                        <TableCell   className="product-code-cell"
                         style={{ ...styles.tableCell2, ...styles.tableCell3 ,
                           width: '200px',
                           maxWidth: '200px',
                           whiteSpace: "normal", // This ensures text does not overflow
                           overflow: 'hidden',
                           textOverflow: 'ellipsis'
                        }}>{product.name} <span className='' >{product.attribute.companyProductCode ? `- (${product.attribute.companyProductCode})` : ''}</span></TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.companyName} </TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.qty} {product.attribute.unitName}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.price}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.discount}%</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.discountAmount}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.gst_percentage}%</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.gstAmount}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.quotationQty}</TableCell>
                        <TableCell style={styles.tableCell5}>{product.attribute.totalPrice}</TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow>
                    <TableCell colSpan={10} align="center" style={styles.tableCell7}>Total Amount</TableCell>
                    <TableCell style={styles.tableCell8}>{roundedTotalAmount}</TableCell>
                    {/* <TableCell style={styles.tableCell4}>854</TableCell> */}
                  </TableRow>
                </TableBody>
                      </Table>

                    </>
                  )
                })
              }
              {!selectedDepartments && <Table style={styles.tablemain} size={"small"}>
                <TableBody >
                  <TableRow style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                    <TableCell style={styles.tableCell}>Sr. No.</TableCell>
                    <TableCell className="product-code-cell"
                     style={{ ...styles.tableCell, ...styles.tableCell3,
                 width: '200px',
                 maxWidth: '200px',
                 whiteSpace: "normal", // This ensures text does not overflow
                 overflow: 'hidden',
                 textOverflow: 'ellipsis'

                     }} >Product Code Name</TableCell>
                    <TableCell style={styles.tableCell}>Company</TableCell>
                    <TableCell style={styles.tableCell}>Pack</TableCell>
                    <TableCell style={styles.tableCell}>List Price (Rs.)</TableCell>
                    <TableCell style={styles.tableCell}>Disc (%)</TableCell>
                    <TableCell style={styles.tableCell}>Disc Price (Rs.)</TableCell>
                    <TableCell style={styles.tableCell}>GST (%)</TableCell>
                    <TableCell style={styles.tableCell}>GST Price (Rs.)</TableCell>
                    <TableCell style={styles.tableCell}>Qty</TableCell>
                    <TableCell style={styles.tableCell}>Total</TableCell>
                  </TableRow>
                  {filteredProducts.map((product: any, index: number) => {
                    return (
                      <TableRow key={product.id} style={styles.tableRow} >
                        <TableCell style={styles.tableCell6}>{index + 1}</TableCell>
                        <TableCell   className="product-code-cell"
                         style={{ ...styles.tableCell2, ...styles.tableCell3 ,
                           width: '200px',
                           maxWidth: '200px',
                           whiteSpace: "normal", // This ensures text does not overflow
                           overflow: 'hidden',
                           textOverflow: 'ellipsis'
                        }}>{product.name} <span className='' >{product.attribute.companyProductCode ? `- (${product.attribute.companyProductCode})` : ''}</span></TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.companyName} </TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.qty} {product.attribute.unitName}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.price}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.discount}%</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.discountAmount}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.gst_percentage}%</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.gstAmount}</TableCell>
                        <TableCell style={styles.tableCell2}>{product.attribute.quotationQty}</TableCell>
                        <TableCell style={styles.tableCell5}>{product.attribute.totalPrice}</TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow>
                    <TableCell colSpan={10} align="center" style={styles.tableCell7}>Total Amount</TableCell>
                    <TableCell style={styles.tableCell8}>{roundedTotalAmount}</TableCell>
                    {/* <TableCell style={styles.tableCell4}>854</TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>}
            </Container>
          </StyledTableContainer>
          <Container>
            <Termsconditions
             order={order}
              style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}
               selecteduitable={selecteduitable} />
          </Container>
        </PrintStyles>
        <br />
        <br />
        <br />
        <PrintStyles ref={printRef2}>
          {!selecteduitable || selecteduitable === 'UNIQUE FIRM' && (
            <Container>
              <UniqueBiologicalHeader width={100} selecteduitable={selecteduitable} order={order} />
            </Container>
          )}
          {selecteduitable === 'SOHAM FIRM' && (
            <Container>
              <SohamHeader width={100} selecteduitable={selecteduitable}  order={order} />
            </Container>
          )}
          <Container>
            <Box sx={{ overflowX: 'auto', maxHeight: '500px',
              //  border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)" : '1px solid rgb(155 177 90 / 80%)' 
               }} className='product-code-cell-code'>
              <Table style={{ ...styles.tablemain, width: '100%' }} size={"small"}>
                <TableBody>
                  {/* <TableRow style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }}>Sr. No.</TableCell>
                    <TableCell style={{
                      ...styles.tableCell, ...styles.tableCell3, textAlign: 'center', maxWidth: '1000px',
                      minWidth: '380px', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)'
                    }} className="product-code-cell">Name of Chemicals</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }} colSpan={3}>Pack</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }} colSpan={3}>Rate</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }} colSpan={3}>Dis.%</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }} colSpan={3}>Dis. Amt</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }} colSpan={3}>GST</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBottomColor: 'rgb(155 177 90 / 80%)' }} colSpan={3}>Final Amount</TableCell>
                  </TableRow> */}
                  {/* <TableRow style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)' }}></TableCell>
                    <TableCell style={{
                      ...styles.tableCell, ...styles.tableCell3, textAlign: 'center', maxWidth: '1000px',
                      minWidth: '380px', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)'
                    }} className="product-code-cell"></TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none' }}>PCL</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none', borderLeft: 'none' }}>RLCC</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderLeft: 'none' }}>LOBA</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none' }}>PCL</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none', borderLeft: 'none' }}>RLCC</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderLeft: 'none' }}>LOBA</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none' }}>PCL</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none', borderLeft: 'none' }}>RLCC</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderLeft: 'none' }}>LOBA</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none' }}>PCL</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none', borderLeft: 'none' }}>RLCC</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderLeft: 'none' }}>LOBA</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none' }}>PCL</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none', borderLeft: 'none' }}>RLCC</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderLeft: 'none' }}>LOBA</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none' }}>PCL</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderRight: 'none', borderLeft: 'none' }}>RLCC</TableCell>
                    <TableCell style={{ ...styles.tableCell, textAlign: 'center', border: '1px solid white', borderBlockColor: 'rgb(155 177 90 / 80%)', borderLeft: 'none' }}>LOBA</TableCell>
                  </TableRow> */}
                  {/* {filteredprintexl.map((product: any, index: number) => (
                    <TableRow key={product.id} style={styles.tableRow}>
                      <TableCell style={styles.tableCell2}>{index + 1}</TableCell>
                      <TableCell style={{
                        ...styles.tableCell2, ...styles.tableCellexl, maxWidth: '1000px',
                        minWidth: '380px'
                      }} className="product-code-cell">{product.productName}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pack}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pack}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pack}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pclRate || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.rlccRate || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.lobaRate || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pclDiscountPercent || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.rlccDiscountPercent || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.lobaDiscountPercent || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pclDiscountAmt || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.rlccDiscountAmt || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.lobaDiscountAmt || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pclGst || '0'}%</TableCell>
                      <TableCell style={styles.tableCell2}>{product.rlccGst || '0'}%</TableCell>
                      <TableCell style={styles.tableCell2}>{product.lobaGst || '0'}%</TableCell>
                      <TableCell style={styles.tableCell2}>{product.pclFinalAmt || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.rlccFinalAmt || '0'}</TableCell>
                      <TableCell style={styles.tableCell2}>{product.lobaFinalAmt || '0'}</TableCell>
                    </TableRow>
                  ))} */}
                  {
                    companies && companies.length > 0 && <>
                    <TableRow style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
  <TableCell
    style={{
      ...styles.tableCell,
      textAlign: 'center',
      border: selecteduitable == "SOHAM FIRM" ? "1px solid  rgb(190 190 190)" :'1px solid rgb(155 177 90 / 80%)',
      borderBottomColor: selecteduitable == "SOHAM FIRM" ? "rgb(190 190 190)" : 'rgb(155 177 90 / 80%)'
    }}
  >
    Sr. No.
  </TableCell>

  <TableCell
    style={{
      ...styles.tableCell,
      ...styles.tableCell3,
      width: '200px',
      maxWidth: '200px',
      whiteSpace: "normal", // This ensures text does not overflow
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(190 190 190)" :'1px solid rgb(155 177 90 / 80%)',
      borderBottomColor: selecteduitable == "SOHAM FIRM" ? "rgb(190 190 190)" : 'rgb(155 177 90 / 80%)'
    }}
    className="product-code-cell"
  >
    Name of Chemicals
  </TableCell>

  {/* Dynamically create columns for each group */}
  {columnGroups.map((group:any) => (
    <TableCell
      key={group.label}
      style={{
        ...styles.tableCell,
        textAlign: 'center',
        border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(190 190 190)" :'1px solid rgb(155 177 90 / 80%)',
        borderBottomColor: selecteduitable == "SOHAM FIRM" ? "rgb(190 190 190)" : 'rgb(155 177 90 / 80%)'
       }}
      colSpan={group.colSpan}
    >
      {group.label}
    </TableCell>
  ))}
</TableRow>

                    <TableRow style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                                  {/* Static columns */}
                                  <TableCell
                                    style={{
                                      ...styles.tableCell,
                                      textAlign: 'center',
                                      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(190 190 190)" :'1px solid rgb(155 177 90 / 80%)',
                                      borderBottomColor: selecteduitable == "SOHAM FIRM" ? "rgb(190 190 190)" : 'rgb(155 177 90 / 80%)'
                                      }}
                                  ></TableCell>
                                  <TableCell
                                    style={{
                                      ...styles.tableCell,
                                      ...styles.tableCell3,
                                      width: '200px',
                                      maxWidth: '200px',
                                      whiteSpace: "normal", // This ensures text does not overflow
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(190 190 190)" :'1px solid rgb(155 177 90 / 80%)',
                                      borderBottomColor: selecteduitable == "SOHAM FIRM" ? "rgb(190 190 190)" : 'rgb(155 177 90 / 80%)'
                                    }}
                                    className="product-code-cell"
                                  ></TableCell>

                                  {/* Dynamic columns based on company names */}
                                  {["Pack", "Rate", "Dis.%", "Dis. Amt", "GST", "Qty", "Final Amount"].map((header, headerIndex) => (
                                    companies.filter((c:any) =>
                                      selectedCompany ? (
                                        selectedCompany.includes(c.id) 
                                      ) : true
                                    ).map((company:any, companyIndex) => (
                                      <TableCell
                                        key={`${header}-${company.id}`}
                                        style={{
                                          ...styles.tableCell,
                                          textAlign: 'center',
                                          border: selecteduitable == "SOHAM FIRM" ? "1px solid rgb(190 190 190)" :'1px solid rgb(155 177 90 / 80%)',
      borderBottomColor: selecteduitable == "SOHAM FIRM" ? "rgb(190 190 190)" : 'rgb(155 177 90 / 80%)'
   // Remove right border for the last company of each header section
                                          // borderRight: companyIndex === companies.length - 1 ? 'none' : undefined,
                                          // Remove left border for all companies except the first one
                                          // borderLeft: companyIndex === 0 ? undefined : 'none'
                                        }}
                                      >
                                        {company.name}
                                      </TableCell>
                                    ))
                                  ))}
                                </TableRow>
                           </>
                  }
                 

                  {filteredprintexl.map((product: any, index: number) => (
                          <TableRow key={product.id} style={styles.tableRow}>
                            {/* Static columns */}
                            <TableCell style={styles.tableCell6}>{index + 1}</TableCell>
                            <TableCell
                              style={{
                                ...styles.tableCell2,
                                ...styles.tableCellexl,
                                width: '200px',
                                maxWidth: '200px',
                                whiteSpace: "normal", // This ensures text does not overflow
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              className="product-code-cell"
                            >
                              {product.productName}
                            </TableCell>
                            
                            {/* Pack columns for each company */}
                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any) => (
                                 <TableCell key={`pack-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}Rate`] ? product.pack : '-'}
                              </TableCell>
                            ))}

                            {/* Rate columns for each company */}
                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any) => (
                              <TableCell key={`rate-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}Rate`] || '0'}
                              </TableCell>
                            ))}

                            {/* Discount Percent columns for each company */}
                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any) => (
                              <TableCell key={`discountPercent-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}DiscountPercent`] || '0'}%
                              </TableCell>
                            ))}

                            {/* Discount Amount columns for each company */}
                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any) => (
                              <TableCell key={`discountAmt-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}DiscountAmt`] || '0'}
                              </TableCell>
                            ))}

                            {/* GST columns for each company */}
                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any) => (
                              <TableCell key={`gst-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}Gst`] || '0'}%
                              </TableCell>
                            ))}

                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any) => (
                              <TableCell key={`Qty-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}Qty`] || '0'}
                              </TableCell>
                            ))}

                            {/* Final Amount columns for each company */}
                            {companies.filter((c:any) =>
                                            selectedCompany ? (
                                              selectedCompany.includes(c.id) 
                                            ) : true
                                          ).map((company:any,index33:any) => (
                              <TableCell key={`finalAmt-${company.id}`} style={
                                companies.filter((c:any) =>
                                  selectedCompany ? (
                                    selectedCompany.includes(c.id) 
                                  ) : true
                                ).length == (index33 + 1) ? 
                                styles.tableCell5 :
                                styles.tableCell2
                              }>
                                {product[`${company.name.toLowerCase()}FinalAmt`] || '0'}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                  {/* <TableRow>
                  <TableCell colSpan={19} align="center" style={{ ...styles.tableCell4 }}>Total Amount</TableCell>
                  <TableCell style={styles.tableCell4}>{exlroundedTotalAmount}</TableCell>
                </TableRow> */}
                </TableBody>
              </Table>
            </Box>
          </Container>
          <Container>
            <Termsconditions
            order={order}
            style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}
             selecteduitable={selecteduitable} />
          </Container>
        </PrintStyles>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Data processed successfully!
          </Alert>
        </Snackbar>
      </StyledContainer>
    </>
  );
};

export default OrderDetailPage;
