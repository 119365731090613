import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled component for the header section
const InvoiceHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
   // Light blue background
  borderBottom: '1px solid black',
}));
const InvoiceHeader2= styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: '#c4daff',
     // Light blue background
    borderBottom: '1px solid black',
    width:"68%"
  }));
  
// Styled component for the invoice number
const InvoiceNumber = styled(Typography)(({ theme }) => ({
  color: 'red',
  fontWeight: 'bold',
  paddingLeft:"10px"
}));

// Main component for the invoice header
const InvoiceHeaderComp = ({id}:any) => {
  return (
    <Box sx={{  padding: '10px' }}>
      <Typography variant="subtitle2" sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '5px' }}>
        TAX INVOICE
      </Typography>
      
      <InvoiceHeader>
        <InvoiceHeader2>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black',  }}>
          ONKAR TRADERS
        </Typography>
        </InvoiceHeader2>
       
        <Typography variant="body2" sx={{ color: 'black' }}>
          Original for Buyer
        </Typography>
      </InvoiceHeader>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
        <Typography variant="caption" sx={{ fontSize: '12px' }}>
          R.S. NO. 150/3, JAY BHAVANI COLONY, PACHGAON - GIRGAON ROAD,
          PACHGAON, KOLHAPUR - 416013, MAHARASHTRA. MOB NO. - 7083756464
        </Typography>
        <Typography variant="body2" style={{    width: "400px"}}>
          <div style={{display:"flex"}}>Invoice no - <InvoiceNumber> OT/24-25/{id}</InvoiceNumber></div>  
          <label>Date -</label>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
        <Typography variant="caption" sx={{ fontSize: '12px',fontWeight:700 }}>
         GSTIN/UIN - 27FYBPD9950G1Z8
        </Typography>
        <Typography variant="caption" sx={{ fontSize: '12px',fontWeight:700 }} >
        PAN NO - FYBPD9950G
        </Typography>
      </Box>
    </Box>
  );
};

export default InvoiceHeaderComp;
