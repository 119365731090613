import React from 'react';
import OrderListUI from "../../components/admin/order/components/OrderList";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const OrderList = () => {
  return (
    <div>
   <PersistentDrawerLeft>
      <OrderListUI/>
     </PersistentDrawerLeft>
    </div>
  );
};

export default OrderList;