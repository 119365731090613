import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [ 
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }], 
        [
            {
                'color': [
                    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
                ]
            },
            {
                'background': [
                    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
                ]
            }
        ],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }], 
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const formats = [
    'size', 'header', 'color', 'background',
    'bold', 'italic', 'underline', 'strike',
    'script', 'list', 'bullet', 'indent', 'align',
    'link', 'image', 'video'
];

const Quilleditor = ({ value, setValue }: any) => {

    return (
        <>
            <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules}
                formats={formats} />
        </>
    )
}

export default Quilleditor;