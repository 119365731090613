import React from 'react';
import OrderUI from "../../components/admin/order/components/Order";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const Order = () => {
  return (
    <div>
    <PersistentDrawerLeft>
      <OrderUI/>
    </PersistentDrawerLeft>
    </div>
  );
};

export default Order;