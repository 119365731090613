import React from 'react';
import OrderDetailUI from "../../components/admin/order/components/OrderDetail";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const OrderDetail = () => {
  return (
    <div>
      <PersistentDrawerLeft>
      <OrderDetailUI />
      </PersistentDrawerLeft>
    </div>
  );
};

export default OrderDetail;