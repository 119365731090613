import React, { useState, useEffect } from "react";
import { Grid, Card, Typography } from "@mui/material";
import { Container } from "../styles/Dashboard.style";
import {
  getProductCount, getOrderCount, getTotalCompletedOrderAmount,
  // getOutOfStockCount,
  getOrdersPerDay, getTotalPricePerDay
} from "../services/dashboard";
import OrderPerDayUI from "./OrderPerDay";
import TotalPricePerDay from "./TotalPricePerDay";
import Quilleditor from "../../../reusable/Quilleditor";
const Dashboard: React.FC = () => {
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [totalCompletedOrderAmount, setTotalCompletedOrderAmount] = useState(0);
  const [outOfStockCount, setOutOfStockCount] = useState(0);
  const [ordersPerDay, setOrdersPerDay] = useState([]);
  const [totalPricePerDay, setTotalPricePerDay] = useState([]);


  const [editorValue, setEditorValue] = useState('');


  useEffect(() => {
    fetchProductCount();
    fetchOrderCount();
    fetchTotalCompletedOrderAmount();
    // fetchOutOfStockCount();
    fetchOrdersPerDay();
    fetchTotalPricePerDay();
  }, [])
  const fetchProductCount = async () => {
    try {
      const response: any = await getProductCount();
      setProductCount(response.data?.totalCount || 0)
    } catch (error) {

    }
  }
  const fetchOrderCount = async () => {
    try {
      const response: any = await getOrderCount();
      setOrderCount(response.data?.totalCount || 0)
    } catch (error) {

    }
  }
  const fetchTotalCompletedOrderAmount = async () => {
    try {
      const response: any = await getTotalCompletedOrderAmount();
      setTotalCompletedOrderAmount(response.data?.totalCount || 0)
    } catch (error) {
    }
  }
  // const fetchOutOfStockCount = async () => {
  //   try {
  //     const response: any = await getOutOfStockCount();
  //     setOutOfStockCount(response.data?.outOfStockCount || 0)
  //   } catch (error) {
  //   }
  // }
  const fetchOrdersPerDay = async () => {
    try {
      const response: any = await getOrdersPerDay();
      setOrdersPerDay(response.data?.ordersPerDay)
    } catch (error) {
    }
  }
  const fetchTotalPricePerDay = async () => {
    try {
      const response: any = await getTotalPricePerDay();
      console.log("totalPricePerDay", response)
      setTotalPricePerDay(response.data?.totalPricePerDay)
    } catch (error) {
    }
  }
  return (
    <Container>
      <h4 style={{ fontFamily: 'auto', fontWeight: '500' }}>Dashboard</h4>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
          <span style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }} >UNIQUE BIOLOGICAL & CHEMICALS</span>
        </Grid>
      </Grid>
      <br />

      {/* <Quilleditor
                value={editorValue}
                setValue={setEditorValue}
            /> */}
      <div>
        {
          <div dangerouslySetInnerHTML={{ __html: editorValue }} />
        }
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card sx={{ width: "100%", height: "100px", backgroundColor: "#CBDCEB" }}>
            <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
              {productCount}
            </Typography>
            <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center", fontStyle: 'italic', fontSize: '15px' }}>
              Product 
            </Typography>

          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card sx={{ width: "100%", height: "100px", backgroundColor: "#98d3ff" }}>
            <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
              {orderCount}
            </Typography>
            <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center", fontStyle: 'italic', fontSize: '15px' }}>
              Quotation 
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card sx={{ width: "100%", height: "100px", backgroundColor: "#d6f0ff" }}>
            <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
              {totalCompletedOrderAmount}
            </Typography>
            <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center", fontStyle: 'italic', fontSize: '15px' }}>
              Quotation Granted
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }}>Quotation Current Month</Typography>
          <OrderPerDayUI data={ordersPerDay} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }}>Quotation of Year</Typography>
          <TotalPricePerDay data={totalPricePerDay} />
        </Grid>
      </Grid>
    </Container>
  )
}
export default Dashboard;

