import React,{useState} from "react";
import { Grid, Box, Typography, IconButton, TextField, FormControl,
  Dialog,DialogActions,DialogTitle,Button,Select,InputLabel,MenuItem
 } from "@mui/material";
 import TableViewIcon from '@mui/icons-material/TableView';
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/system';
import { jwtDecode } from "jwt-decode"; 

const ProductContainer = styled(Box)`
  border: 1px solid #e0e0e0;
  padding: 10px;
  padding-left:16px;
  padding-right:16px;
  border-radius: 0px;
  bottom-
  margin: 0 px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
`;

const ProductDetails = styled(Grid)`
  
`;// display: flex;
// align-items: center;

const ProductActions = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface OrderProductBoxProps {
  product: any;
  onQtyChange: any;
  deleteOrderedProduct:any;
  units:any;
  onUnitChange:any;
  gstRates:any;
  onGstChange:any;
  onUnitQtyChange:any;
  onPriceChange:any;
  onDiscountChange:any;
  isDuplicate:any;
  index:any;
  orderId:any;
}

const OrderProductBox = ({ product, onQtyChange,deleteOrderedProduct,units,onUnitChange,gstRates ,onGstChange,
  onUnitQtyChange, onPriceChange, onDiscountChange,isDuplicate, index, orderId}: OrderProductBoxProps) => {
  const token = localStorage.getItem("token") || ""; 
  const decoded: { id: number; role: string } = jwtDecode(token);
  const [errorMessage,setErrorMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [subUnits,setSubUnits] = useState(units)
  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value) == 0){
      setErrorMessage("Product qty is must be greater than zero.")
      onQtyChange(product,event.target.value);
    }else{
      setErrorMessage("")
      onQtyChange(product,event.target.value);
    }
  };
  const handleCloseError = () => {
    setOpenError(false);
  };
  const handleChangeUnit = (event:any) => {
   if(event.target.value){
    onUnitChange(product,event.target.value);
   }
  }
  const handleChangeGst = (event:any) => {
     onGstChange(product,event.target.value);
   }
   const handleUnitQtyChange = (event:any) => {
      onUnitQtyChange(product,event.target.value);
    
   }
   const handlePriceChange = (event:any) => {
    onPriceChange(product,event.target.value);
   }
   const handleDiscountChange = (event:any) => {
    onDiscountChange(product,event.target.value);
   }
  return (
    <ProductContainer style={ isDuplicate(product) ?{background: "#bcfebc"} : {}}>
      <ProductDetails container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="body2" style={{fontSize:"13px"}}>{product.name} 
          {
            product.attribute.companyName && <>
              <Typography variant="body2" style={{color: "grey",fontSize: "12px",display: "flex"}}>
              {
                              isDuplicate(product) &&  <ErrorIcon style={{color:"red"}}/>
                            }   <TableViewIcon style={{fontSize: "15px"}}/>
          <label >{product.attribute.companyName}
             {product.attribute.companyProductCode && <> ({product.attribute.companyProductCode})</> } 
           
             </label></Typography>
        
      </>  
          } </Typography>
         
          </Grid>
   
        <Grid item xs={12} sm={6} md={6} lg={1}>
        <FormControl fullWidth size="small">
            <TextField
              label="Price"
              id="price"
              type="number"
              size="small"
              disabled={!(decoded.role === "ADMIN")}
              value={product.attribute.price}
              onChange={handlePriceChange}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
          
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={1}>
        <FormControl fullWidth size="small">
            <TextField
              label="Qty"
              id="qty"
              type="number"
              size="small"
              value={product.attribute.qty}
              disabled={!(decoded.role === "ADMIN")}
              onChange={handleUnitQtyChange}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
        
        </Grid>
     
        <Grid item xs={12} sm={12} md={6} lg={1}>
        <FormControl fullWidth size="small"> 
              <InputLabel>
                Unit <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                name="unitId"
                label="Unit *"
                disabled={!(decoded.role === "ADMIN")}
                value={product.attribute.unitId}
                onChange={handleChangeUnit}
                fullWidth
              >{
                subUnits && subUnits.length > 0 && (
                  subUnits.map((e:any,index:number)=>{
                    return (
                      <MenuItem value={e.id} key={"Unit"+index}>{e.unit_short_name}</MenuItem>
                    )
                  })
                )
              }
              </Select>
            </FormControl>
        </Grid>
       
        <Grid item xs={12} sm={12} md={6} lg={1}>
          <FormControl fullWidth size="small">
            <TextField
              label="Discount"
              id="discount"
              type="number"
              size="small"
              value={product.attribute.discount}
              onChange={handleDiscountChange}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={1}>
          <FormControl fullWidth size="small">
            <TextField
              label="Landing Price"
              id="LandingPrice"
              type="number"
              size="small"
              value={product.attribute.landingPrice}
              disabled={true}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
         <Grid item xs={12} sm={12} md={6} lg={1}>
          <FormControl fullWidth size="small"> 
                <InputLabel>
                GST <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Select
                  name="gstRate"
                  label="GST *"
                  value={product.attribute.gst_percentage}
                  onChange={handleChangeGst}
                  fullWidth
                >{
                  gstRates && gstRates.length > 0 && (
                    gstRates.map((e:any,index:number)=>{
                      return (
                        <MenuItem value={e.rate} key={"Unit"+index}>{e.rate}%   </MenuItem>
                      )
                    })
                  )
                }
                </Select>
              </FormControl>
          </Grid>
         <Grid item xs={12} sm={12} md={6} lg={1}>
          <FormControl fullWidth size="small">
            <TextField
              label="Q-Qty"
              id="quotationQty"
              type="number"
              size="small"
              value={product.quotationQty}
              onChange={handleQtyChange}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
       
        <Grid item xs={12} sm={6} md={6} lg={1}>
          <Typography variant="body2">
          <label  style={{color:"grey"}}> Subtotal: </label> ₹{(product.attribute.totalPrice)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={1}>
          {
            ( isDuplicate(product) ) &&  <IconButton color="error" aria-label="delete" onClick={()=>{
              deleteOrderedProduct(product.attribute.id,index)
          }}>
            <DeleteIcon />
          </IconButton>
          }
       
        </Grid>
        {
          errorMessage &&  <Grid item xs={12} sm={12} md={12} lg={12}>
          <label style={{color:"red"}}>{errorMessage}</label>
          </Grid>
        }
       
      </ProductDetails>
      {/* <ProductActions item xs={1}>
       
      </ProductActions>  */}
      
      <Dialog
        open={openError}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
              {errorMessage}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseError}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ProductContainer>
  );
};

export default OrderProductBox;
