import React from 'react';
import LoginUI from "../components/login/components/Login";
const Login = () => {
  return (
    <div>
      <LoginUI/>
    </div>
  );
};

export default Login;