import React,{useEffect} from 'react';
import { Typography, Grid, Paper,IconButton } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from "@mui/icons-material/Add";

const ProductContainer = styled("div")`
 
  @media (max-width: 600px) {
    padding: 8px;
  };

`;

const ProductTitle = styled(Typography)`
      margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.334;
    letter-spacing: 0em;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    padding-left: -20px;
    margin-left: -17px;
  padding: 16px;
  padding-bottom: 5px;
`;

const AttributeBox = styled(Paper)`
  padding: 16px;
  margin-bottom: 8px;
  @media (max-width: 600px) {
    padding: 8px;
  };
   cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
const Title = styled(Typography)`
  color: grey;
  font-size: 12px;
`;

const Value = styled(Typography)`
  color: black;
  font-size: 12px;
`;

const  Div = styled("div")`
display: inline-flex;
`;

const ProductDescribe = ({ product,onSelectToOrder,orderProducts }: any) => {
    const onClickProduct = (attr:any) => {
        onSelectToOrder({
            id:product.id,
            name:product.productName,
            attribute:attr
        })
    }    
  return (
    
        product && product.product_attributes && product.product_attributes.length > 0 &&  (
            <ProductContainer >
            <ProductTitle variant="h5">{product.productName}</ProductTitle>
            <div style={{ width: "100%",
 height: "200px",
 overflowY : "auto",
 paddingTop:"10px",
 paddingBottom:"10px",
 padding:"10px"
 }} >
            <Grid container spacing={2}>
              {product.product_attributes.map((attr: any,index:any) => (
                orderProducts && orderProducts.length > 0 ? 
         
                orderProducts.some((item:any) => item.attribute.id === attr.id)  ? null :
                <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <IconButton onClick={() => onClickProduct(attr)} 
                 aria-label="add" size="small" >
                    <AddIcon />
                  </IconButton> 
                  <Div>
                  
                  <Title>Company:</Title>
                  <Value>{attr.companyName}</Value></Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Code:</Title>
                  <Value>{attr.companyProductCode}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Qty:</Title>
                  <Value>{attr.qty} {attr.unitName}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Price:</Title>
                  <Value>{attr.price}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={1}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>D%:</Title>
                  <Value>{attr.discount}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Final Price:</Title>
                  <Value>{attr.totalPrice}</Value>
                  </Div>

                </Grid>
                <Grid item xs={12} sm={6} md={1}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>GST:</Title>
                  <Value>{attr.gst_percentage}%</Value>
                  </Div>
                </Grid>
              </Grid>
                : 
                
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={2}
                 style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <IconButton onClick={() => onClickProduct(attr)}  aria-label="add" size="small" >
                    <AddIcon />
                  </IconButton> 
                  <Div>
                  
                  <Title>Company:</Title>
                  <Value>{attr.companyName}</Value></Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Code:</Title>
                  <Value>{attr.companyProductCode}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Qty:</Title>
                  <Value>{attr.qty} {attr.unitName}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Price:</Title>
                  <Value>{attr.price}</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={1}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>D%:</Title>
                  <Value>{attr.discount}</Value>
                  </Div>
                </Grid>
               
                <Grid item xs={12} sm={6} md={1}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>GST:</Title>
                  <Value>{attr.gst_percentage}%</Value>
                  </Div>
                </Grid>
                <Grid item xs={12} sm={6} md={2}  style={
                  (index + 1  < (product.product_attributes.length) &&
                  product.product_attributes[index + 1].qty &&
                  product.product_attributes[index + 1].qty != attr.qty) 
                  || index + 1 === product.product_attributes.length  ? 
                  {borderBottom:"1px solid #afafaf"} : {}}>
                <Div>
                  <Title>Final Price:</Title>
                  <Value>{attr.totalPrice}</Value>
                  </Div>

                </Grid>
              </Grid>
              ))}
            </Grid>
            </div>
           
          </ProductContainer>
        )
    
  );
};

export default ProductDescribe