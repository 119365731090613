import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    marginLeft: '0%',
    marginRight: '0%',
    paddingTop: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginLeft: '0%',
      marginRight: '0%',
      padding: theme.spacing(0),
    },
  }));
