import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Container, FormDiv, ButtonCust } from "../styles/Product.style";
import { quotation_list, order_list_total } from "../services/order";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { 
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress, Chip,  
    Typography, TextField 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';     
import { BUTTON_STYLE } from "../../../../constants/style";

const StyledContainer = styled(Container)({
    padding: '20px',
    marginTop: '20px',
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 700,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const ProductList: React.FC = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [searchInput, setSearchInput] = useState<string>('');
    const [quotationId, setQuotationId] = useState<string>('');
    
    // useEffect(() => {
        // fetchProductTotal();
    // }, []);

    useEffect(() => {
        if(page ||  startDate ||  endDate || searchInput || quotationId){
            setPage(0);
         
        }
        
        fetchProductTotal();
        fetchOrders();
    }, [page, rowsPerPage, startDate, endDate, searchInput,quotationId]);

    const fetchProductTotal = async () => {
        const response: any = await order_list_total(startDate ? ""+new Date(startDate).getTime()+"" : "" ,
         endDate ? ""+new Date(endDate).getTime()+"" : "",
        searchInput && searchInput != "" ? searchInput : "",
        quotationId && quotationId != "" ? quotationId : "");
        setTotalRows(response && response.data?.quotationstotal && response.data?.quotationstotal.length > 0 
            ? response.data?.quotationstotal[0].quotationTotal : 0);
    };

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response: any = await quotation_list(page + 1, rowsPerPage, 
              startDate ? ""+new Date(startDate).getTime()+"" : "" , endDate ? ""+new Date(endDate).getTime()+"" : "",
              searchInput && searchInput != "" ? searchInput : "",
              quotationId && quotationId != "" ? quotationId : ""
            );
            setOrders(response.data.quotations);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDetail = (productId: number) => {
        navigate(`/admin/quotation_edit/${productId}`);
    };

    const handleEdit = (productId: number) => {
        navigate(`/admin/quotation_update/${productId}`);
    };

    const handleCreate = () => {
        navigate(`/admin/quotation/create`);
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    return (
        <Container>
            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Quotation List</Typography>
                    <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                        Add
                    </ButtonCust>
                </Box>

                {/* Start and End Date Filters */}
                <Box sx={{ display: 'flex', gap: '16px', marginTop: '20px' }}>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate || ''}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate || ''}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                    />
                    <TextField
                        label="Search Users"
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        type="text"
                        size="small"
                        placeholder="Search your Users"
                    />
                     <TextField
                        label="Search Quotation Id"
                        variant="outlined"
                        value={quotationId}
                        onChange={(e) => setQuotationId(e.target.value)}
                        type="text"
                        size="small"
                        placeholder="Search Quotation Id"
                    />
                   
                </Box>

                <StyledContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <StyledTableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="medium">No.</TableCell>
                                        <TableCell size="medium">User</TableCell>
                                        <TableCell size="medium">Employee</TableCell>
                                        <TableCell size="medium"> Date</TableCell>
                                        <TableCell size="medium">Qty</TableCell>
                                        <TableCell size="medium">Total Price</TableCell>
                                        <TableCell size="medium">Location</TableCell>
                                        <TableCell size="medium">Status</TableCell>
                                        <TableCell size="medium">Order Status</TableCell>
                                        <TableCell size="medium">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders && orders.length > 0 && orders.map((order: any, index) => (
                                        <TableRow key={order.id}>
                                            <TableCell size="small">{order.id}</TableCell>
                                            <TableCell size="small">{order.fullName}  <br/>{order.mobileNo}</TableCell>
                                            <TableCell size="small">{order.employee}</TableCell>
                                            <TableCell size="small">{new Date(order.quotationDate).toLocaleDateString('en-GB')}</TableCell>
                                            <TableCell size="small">{order.totalQty}</TableCell>
                                            <TableCell size="small">{order.totalPrice}</TableCell>
                                            <TableCell size="small">{order.location}</TableCell>
                                            <TableCell size="small">
                                                <Chip label={order.status} color="primary" size="small" />
                                            </TableCell>
                                            <TableCell size="small">
                                                <Chip label={order.orderStatus ? order.orderStatus : "None"}
                                                 color={
                                                    order.orderStatus  && order.orderStatus === "Granted" ? 
                                                     "success" : "primary"} size="small" />
                                            </TableCell>
                                            
                                            <TableCell size="small">
                                                <IconButton onClick={() =>handleDetail(order.id)}>
                                                    <RemoveRedEyeIcon />
                                                </IconButton>
                                            
                                                <IconButton onClick={() => handleEdit(order.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableContainer>
                    )}
                </StyledContainer>
            </FormDiv>  
        </Container>
    );
};

export default ProductList;
