import React from 'react';
import OrderUpdate from "../../components/admin/order/components/OrderUpdate";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const OrderEdit = () => {
  return (
    <div>
      <PersistentDrawerLeft>
        <OrderUpdate />
      </PersistentDrawerLeft>
    </div>
  );
};

export default OrderEdit;