import React from "react";
import { Row, Col, Typography } from "antd";

const Footer = () => {
  return (
    <Row
      className="footer"
      style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1 }}
      justify="center"
    >
      <Col span={24} style={{ background: "linear-gradient(90deg, #f3efe8, #f9fadd)", padding: "10px", textAlign: 'center' }}>
        <Typography.Text style={{ color: "black", 'fontSize': '12px' }}>
           Copyright © 2024 DevMinds Software All Rights Reserved. Designed & developed by {" "}
          <Typography.Link
            href="https://devminds.co.in/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue" }}
          >
            DevMinds software
          </Typography.Link>
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default Footer;
