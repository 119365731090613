import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Container, FormDiv, ButtonCust } from "../styles/Product.style";
import { product_list, product_list_total } from "../services/product";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { 
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress, 
    Typography, TextField, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';     
import { BUTTON_STYLE, BUTTON_STYLE2 } from "../../../../constants/style";

const StyledContainer = styled(Container)({
    padding: '20px',
    marginTop: '20px',
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 700,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});
const CancelIconDIv = styled("div")({
  position: "relative",
});
const CancelFilterIcon = styled(CancelIcon)({
  position: "absolute",
  right: "-9px",
  zIndex: "1",
  top: "-14px",
  background: "white",
  borderRadius: "10px",
  cursor: "pointer"
})

const ProductList: React.FC = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        fetchProductTotal();
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [page, rowsPerPage]);

    const fetchProductTotal = async () => {
        fetchProductListTotal(searchInput)
          };
    const fetchProductListTotal = async (search:string) => {
        const response: any = await product_list_total(search);
        setTotalRows(response && response.data?.data && response.data?.data?.length > 0 ? response.data?.data[0].total : 0);
  
    }
    const fetchProducts = async () => {
      await fetchProductList(page + 1, rowsPerPage,searchInput);
    };
    const fetchProductList = async (page:number,rowsPerPage:number, searchInput:string) => {
      setLoading(true);
      try {
          const response: any = await product_list(page, rowsPerPage, searchInput);
          setProducts(response.data.data);
      } catch (error) {
          console.error('Error fetching products:', error);
      } finally {
          setLoading(false);
      }
  };
    const handleSearch = () => {
        setPage(0);
        fetchProducts();  // Trigger the API call when the search button is clicked
        fetchProductTotal();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEdit = (productId: number) => {
        navigate(`/admin/product_edit/${productId}`);
    };

    const handleCreate = () => {
        navigate(`/admin/product`);
    };

    const clearFilter = async () => {
      setPage(0);
     setSearchInput("");
      fetchProductList(1, rowsPerPage, '',);
      fetchProductListTotal("",)
    }
    return (
        <Container>
            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Product List</Typography>
                    <div>
                    <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                        Add
                    </ButtonCust>
                    <Button variant="contained" color="primary" onClick={()=>{
                          navigate(`/admin/product_import`);
                    }}  sx={BUTTON_STYLE2}>
                        Import
                    </Button>
                    </div>
                 
                </Box>

                {/* Stock Quantity Filters */}
                <Box sx={{ margin: '20px 0', display: 'flex', gap: '16px' }}>
                    <TextField
                        label="Search Product"
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        type="text"
                        size="small"
                        placeholder="Search your product"
                    />
                   
                   
                    <CancelIconDIv>
                      {
                        searchInput != "" &&
                        <CancelFilterIcon onClick={() =>{
                          clearFilter()
                        }} />
                      }
                    
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                        Search
                    </Button>
                    </CancelIconDIv>
                    
                </Box>

                <StyledContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <StyledTableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No.</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Sub-Category</TableCell>
                                        <TableCell>Created Date</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((product: any, index) => (
                                        <TableRow key={product.productId}>
                                            <TableCell size="small">{product.productId}</TableCell>
                                            <TableCell size="small">{product.productName}</TableCell>
                                            <TableCell size="small">{product.categoryName}</TableCell>
                                            <TableCell size="small">{product.subCategoryName}</TableCell>
                                            <TableCell size="small">{new Date(product.createdDttm).toLocaleDateString('en-GB')}</TableCell>
                                            <TableCell size="small">
                                                <IconButton onClick={() => handleEdit(product.productId)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableContainer>
                    )}
                </StyledContainer>
            </FormDiv>
        </Container>
    );
};

export default ProductList;
