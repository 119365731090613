import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from "./routes";

export default function BasicButtons() {
  return (
    <BrowserRouter>
    <AppRoutes/>
  </BrowserRouter>
  );
}