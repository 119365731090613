import axios from "axios";
import {restApi} from "../../../../constants/api";
export const getProductCount = async () => {
        const response = await restApi("dashbaord/product-count","GET",{});
        return response 
};
export const getOrderCount = async () => {
    const response = await restApi("dashbaord/order-count","GET",{});
    return response 
};
export const getTotalCompletedOrderAmount = async () => {
    const response = await restApi("dashbaord/total-count-granted-quotation","GET",{});
    return response 
};
// export const getOutOfStockCount = async () => {
//     const response = await restApi("dashboard/out-of-stock-count","GET",{});
//     return response 
// };

export const getOrdersPerDay = async () => {
    const response = await restApi("dashbaord/orders-per-day","GET",{});
    return response 
};

export const getTotalPricePerDay = async () => {
    const response = await restApi("dashbaord/total-count-per-month","GET",{});
    return response 
};


