import * as React from 'react';
import Button from '@mui/material/Button';
import {Dialog,AppBar,Toolbar,IconButton,Typography,Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import Invoice from './Invoice';
import { useParams } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogInvoice({data,open,setOpen,setOrderProducts,order}:any) {
  const {orderId} =useParams();  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(!orderId){
      setOrderProducts([]);
    }
   
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{
            backgroundColor: "white",
            color: "black"
          }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Invoice Bill 
            </Typography>
            
          </Toolbar>
        </AppBar> <br/>
       <Invoice data={data} order={order} />
      </Dialog>
    </React.Fragment>
  );
}
