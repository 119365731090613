import React, { useState, useEffect,useCallback } from 'react';
import { Button, Box, Grid, Typography, Dialog,DialogActions,DialogTitle,FormControl,
  TextField,InputLabel,InputAdornment,Select,MenuItem,IconButton
 } from '@mui/material';
 import {  Modal } from 'antd';
 import dayjs, { Dayjs } from 'dayjs';
 import WarningIcon from '@mui/icons-material/Warning';
 import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Container,FormDiv,ButtonCust } from "../styles/Product.style";
import {BUTTON_STYLE, CANCEL_BUTTON_STYLE} from "../../../../constants/style"
import { useNavigate, useParams } from 'react-router-dom';
import ProductSearch from "./ProductSearch";
import OrderProductBox from "./OrderProductBox";
import {get_companies} from "../../product/services/product";
import {create_order,get_units,get_gst_rates,fetch_batch_product,
  order_detail, update_order,insert_batch_quotation} from "../services/order";
import CustomizedSnackbars from "../../../reusable/CustomizedSnackbars";
import * as XLSX from "xlsx";
import DialogInvoice from './DialogInvoice';
// import GstSwitch from "./GstSwitch";
import {calculatePrice} from "../constants/index";
import Quilleditor from '../../../reusable/Quilleditor';
import UserSearch from "./UserSearch";
import { IMG_BASE_URL, insertQuotationAttriCount, insertQuotationAttriFetchCount, WEB_BASE_URL } from '../../../../constants';
import NotExitsProductsModal from './NotExitsProductsModal';

const OrderForm: React.FC = () => {
  const { orderId } = useParams();
  const [visibleProducts, setVisibleProducts] = useState(25);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [withGst,setWithGst] = useState(2);
  const [excelLoading, setExcelLoading] = useState(false);
  const [status,setStatus] = useState("Pending");
  const [orderStatus,setOrderStatus] = useState("Pending");
  const [units,setUnits] = useState([]);
  const [openInvoiceDailog, setOpenInvoiceDailog] = React.useState(false);
  const [orderProducts, setOrderProducts ] = useState<any>([]);
  const [termsAndConditions, setTermsAndConditions] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [companies,setCompanies] = useState([]);
  const [companyId,setCompanyId] = useState("1000000000");
  const [dateOfEntry, setDateOfEntry] = useState<Dayjs | null>(null);
  const [orderDate, setOrderDate] = useState<Dayjs | null>(null);
  const [enquiryDate, setEnquiryDate] = useState<Dayjs | null>(null);
  const [sendingDate, setSendingDate] = useState<Dayjs | null>(null);
  const [enquiryEmail, setEnquiryEmail] = useState(null);
  const [enquiryMobileNo, setEnquiryMobileNo] = useState(null);
  const [selectedUserId,setSelectedUserId] = useState<any>({});
  const [duplicates,setDuplicate] = useState<any>([]);
  const [order, setOrder] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [ openSnackBars,setOpenSnackBars] = useState(false);
  const [gstRates,setGstRates] = useState([]);
  const [notExitsOrderData, setNotExitsOrderData] = useState<any>([]);
  const [openExitsModal, setOpenExitsModal] = useState(false);
  useEffect(()=>{
    fetchUnits();
    fetch_companies();
    fetchGstRates();
  },[]);
  useEffect(() => {
    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);
  const fetchOrderDetail = async () => {
    setExcelLoading(true);
    const response: any = await order_detail(orderId);
    setOrder(response?.data?.quotation || {});
    console.log("response?.data?.quotation",response?.data?.quotation)
    setTermsAndConditions(response?.data?.quotation?.termsAndConditions)
    console.log("response?.data?.quotation?.userId",response?.data?.quotation?.userId)
    setSelectedUserId({id:response?.data?.quotation?.userId,fullName:response?.data?.quotation?.fullName})
    setDateOfEntry(response?.data?.quotation?.dateOfEntry 
      ? dayjs(response.data.quotation.dateOfEntry) 
      : null)
    setOrderDate(response?.data?.quotation?.orderDate 
      ? dayjs(response.data.quotation.orderDate) 
      : null)
      setLocation(response?.data?.quotation?.location)
    setEnquiryDate(response?.data?.quotation?.enquiryDate
      ? dayjs(response.data.quotation.enquiryDate) 
      : null)
    setSendingDate(response?.data?.quotation?.sendingDate
      ? dayjs(response.data.quotation.sendingDate) 
      : null)
    setEnquiryEmail(response?.data?.quotation?.enquiryEmail)
    setEnquiryMobileNo(response?.data?.quotation?.enquiryMobileNo)
    setData(response?.data?.quotationProducts)
    setOrderStatus(response?.data?.quotation?.orderStatus)
    setStatus(response?.data?.quotation?.status)
    const transformedData = response?.data?.quotationProducts?.map((item: any, index: any) => {
      return {
        id: index + 1,
        name: item.productName,
        attribute: {
          "id": item.id,
          "quotationId": item.quotationId,
          "userId": item.userId,
          "qty": item.qty,
          "productId": item.productId,
          "companyId": item.companyId,
          "price": item.price,
          "landingPrice": item.landingPrice,
          "discount": item.discount,
          "discountValue": item.discountValue,
          "discountAmount": item.discountAmount,
          "gstAmount": item.gstAmount,
          "totalPrice": item.totalPrice,
          "createdDttm": item.createdDttm,
          "gst_percentage": item.gst_percentage,
          "companyProductCode": item.companyProductCode,
          "gstValue": item.gstValue,
          "quotationQty": item.quotationQty,
          "productName": item.productName,
          "subCategoryName": item.subCategoryName,
          "categoryName": item.categoryName,
          "companyName": item.companyName,
          "unitName": item.unitName,
          "unitId": item.unitId
        },
        quotationQty: item.quotationQty
      }
    }) || [];
    setOrderProducts([...transformedData]);
    setExcelLoading(false);
  };
  const fetch_companies= async() => {
    try{
      const response:any = await get_companies();
      setCompanies(response.data.data)
    }catch(error){
    }
  }
  const onSelectToOrder = (product:any) => {
    setErrorMessage("")
    let exitingProduct  = orderProducts.find((e:any)=>e.attribute.id == product.attribute.id)
    if(!exitingProduct){
      let attributes = product.attribute;
      let newProduct: any = { ...product,attribute:attributes }; // Create a copy of the product
      newProduct.quotationQty = 1;
      let newObj = calculatePrice(attributes,newProduct.quotationQty)
      newProduct.attribute = newObj;
      let updatedOrderProducts = [...orderProducts,newProduct]; // Copy the existing orderProducts array
      setOrderProducts([...updatedOrderProducts]); 
    }
    
  } 
 
  // const deleteOrderedProduct = (attributeid:number,index1:any)=>{
  //   let data = orderProducts.filter((e:any,index:any) => !(e.attribute.id == attributeid && index == index1))
  //     setOrderProducts([...data])
  // }
  const deleteOrderedProduct = useCallback(
    (attributeId: number, indexToRemove: number) => {
      setOrderProducts((prevProducts:any) =>
        prevProducts.filter(
          (e: any, index: number) =>
            !(e.attribute.id === attributeId && index === indexToRemove)
        )
      );
    },
    []
  );
  const handleCloseError = () => {
    setOpenError(false);
  };
  const fetchUnits = async () => {
      try{
         const response:any = await get_units();
         setUnits(response.data?.data)
      }catch(error){
        
      }
  }
  const fetchGstRates = async () => {
    try{
       const response:any = await get_gst_rates();
       setGstRates(response.data?.data)
    }catch(error){
      
    }
}
 
//  const onUnitChange = (product: any, unitvalue: any) => {
//     let updatedProducts = [...orderProducts];
//     updatedProducts = updatedProducts.map((e: any) => {
//       if (e.attribute.id === product.attribute.id) {
//         if(orderId && e.attribute.quotationId && e.attribute.quotationId > 0){
//           e.attribute.isUpdate = true
//         }
//         e.attribute.unitId = unitvalue;
//        }
//       return e;
//     });
//     setOrderProducts([...updatedProducts]);
//   };
  

const onUnitChange = useCallback(
  (product: any, unitvalue: any) => {
    console.log("hi");
    setOrderProducts((prevProducts:any) => {
      let isUpdated = false;

      const updatedProducts = prevProducts.map((e: any) => {
        if (e.attribute.id === product.attribute.id) {
          if (e.attribute.unitId === unitvalue) {
            return e; // No change needed
          }

          const updatedAttribute = {
            ...e.attribute,
            unitId: unitvalue,
            isUpdate:
              orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
          };

          isUpdated = true;
          return { ...e, attribute: updatedAttribute };
        }
        return e;
      });

      // Only update state if there were changes
      return isUpdated ? updatedProducts : prevProducts;
    });
  },
  [orderId]
);
// const onUnitQtyChange =  (product: any, value: any) => {
//     let updatedProducts = [...orderProducts];
//     updatedProducts = updatedProducts.map((e: any) => {
//       if (e.attribute.id === product.attribute.id) {
//         if(orderId && e.attribute.quotationId && e.attribute.quotationId > 0){
//           e.attribute.isUpdate = true
//         }
//         e.attribute.qty = value;
//          }
//       return e;
//     });
  
//     setOrderProducts([...updatedProducts]);
//   }
const onUnitQtyChange = useCallback(
  (product: any, value: any) => {
    setOrderProducts((prevProducts:any) => {
      let isUpdated = false;

      const updatedProducts = prevProducts.map((e: any) => {
        if (e.attribute.id === product.attribute.id) {
          // Check if the value is actually changing
          if (e.attribute.qty === value) {
            return e; // No change needed
          }

          const updatedAttribute = {
            ...e.attribute,
            qty: value,
            isUpdate:
              orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
          };

          isUpdated = true;
          return { ...e, attribute: updatedAttribute };
        }
        return e;
      });

      // Only update state if there was a change
      return isUpdated ? updatedProducts : prevProducts;
    });
  },
  [orderId]
);

  // const updatequotationQty = (product: any, value: any) => {
  //   let updatedProducts = [...orderProducts];
  //   updatedProducts = updatedProducts.map((e: any) => {
  //     if (e.attribute.id === product.attribute.id) {
  //       if(orderId && e.attribute.quotationId && e.attribute.quotationId > 0){
  //         e.attribute.isUpdate = true
  //       }
  //       e.quotationQty = value;
  //       let newObj = calculatePrice(e.attribute,value)
  //       e.attribute = newObj;
  //       return e
  //     }
  //     return e;
  //   });
  //   setOrderProducts([...updatedProducts]);
  // };
  const updatequotationQty = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts:any) => {
        let isUpdated = false;
  
        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id) {
            // Check if the value is actually changing
            if (e.quotationQty === value) {
              return e; // No change needed
            }
  
            // Update the attribute and quotationQty
            const updatedAttribute = {
              ...e.attribute,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };
            const newObj = calculatePrice(updatedAttribute, value);
  
            isUpdated = true;
            return {
              ...e,
              attribute: newObj,
              quotationQty: value,
            };
          }
          return e;
        });
  
        // Only update state if there was a change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );


  // const onGstChange  = (product: any, value: any) => {
  //   let updatedProducts = [...orderProducts];
  //   updatedProducts = updatedProducts.map((e: any) => {
  //     if (e.attribute.id === product.attribute.id) {
  //       if(orderId && e.attribute.quotationId && e.attribute.quotationId > 0){
  //         e.attribute.isUpdate = true
  //       }
  //       e.attribute.gst_percentage = value;
  //       let newObj = calculatePrice(e.attribute,e.quotationQty)
  //       e.attribute = newObj;
  //       return e
  //     }
  //     return e;
  //   });
  //   setOrderProducts([...updatedProducts]);
  // };
  const onGstChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts:any) => {
        let isUpdated = false;
  
        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id) {
            // Check if the GST percentage is actually changing
            if (e.attribute.gst_percentage === value) {
              return e; // No change needed
            }
  
            // Mark as updated if necessary
            const updatedAttribute = {
              ...e.attribute,
              gst_percentage: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };
  
            // Recalculate the price based on the updated attribute
            const newObj = calculatePrice(updatedAttribute, e.quotationQty);
  
            isUpdated = true;
            return { ...e, attribute: newObj };
          }
          return e;
        });
  
        // Only update the state if there was an actual change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );
  
  // const onPriceChange = (product: any, value: any) => {
  //   let updatedProducts = [...orderProducts];
  //   updatedProducts = updatedProducts.map((e: any) => {
  //     if (e.attribute.id === product.attribute.id) {
  //       if(orderId && e.attribute.quotationId && e.attribute.quotationId > 0){
  //         e.attribute.isUpdate = true
  //       }
  //       e.attribute.price = value;
  //       let newObj = calculatePrice(e.attribute,e.quotationQty)
  //       e.attribute = newObj;
  //       return e
  //     }
  //     return e;
  //   });
  //   setOrderProducts(updatedProducts);
  // }
  const onPriceChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts:any) => {
        let isUpdated = false;
  
        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id) {
            // Check if the price is actually changing
            if (e.attribute.price === value) {
              return e; // No change needed
            }
  
            // Mark as updated if necessary
            const updatedAttribute = {
              ...e.attribute,
              price: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };
  
            // Recalculate the price
            const newObj = calculatePrice(updatedAttribute, e.quotationQty);
  
            isUpdated = true;
            return { ...e, attribute: newObj };
          }
          return e;
        });
  
        // Only update state if there was a change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );


  // const onDiscountChange =  (product: any, value: any) => {
  //   let updatedProducts = [...orderProducts];
  //   updatedProducts = updatedProducts.map((e: any) => {
  //     if (e.attribute.id === product.attribute.id) {
  //       if(orderId && e.attribute.quotationId && e.attribute.quotationId > 0){
  //         e.attribute.isUpdate = true
  //       }
  //       e.attribute.discount = value;
  //       let newObj = calculatePrice(e.attribute, e.quotationQty)
  //       e.attribute = newObj;
  //       return e
  //     }
  //     return e;
  //   });
  //   setOrderProducts(updatedProducts);
  // }
  const onDiscountChange = useCallback(
    (product: any, value: any) => {
      setOrderProducts((prevProducts:any) => {
        let isUpdated = false;
  
        const updatedProducts = prevProducts.map((e: any) => {
          if (e.attribute.id === product.attribute.id) {
            // Check if the discount value is actually changing
            if (e.attribute.discount === value) {
              return e; // No change needed
            }
  
            // Mark as updated if necessary
            const updatedAttribute = {
              ...e.attribute,
              discount: value,
              isUpdate:
                orderId && e.attribute.quotationId && e.attribute.quotationId > 0,
            };
  
            // Recalculate the price based on the updated attribute
            const newObj = calculatePrice(updatedAttribute, e.quotationQty);
  
            isUpdated = true;
            return { ...e, attribute: newObj };
          }
          return e;
        });
  
        // Only update the state if there was an actual change
        return isUpdated ? updatedProducts : prevProducts;
      });
    },
    [orderId, calculatePrice]
  );

  const createOrderFun = async () => {
    setLoading(true);
    if(orderProducts && orderProducts.length > 0 && selectedUserId && selectedUserId.id > 0 &&
      dateOfEntry && enquiryDate && orderDate && enquiryMobileNo && 
      String(enquiryMobileNo).length >= 10 && enquiryEmail
    ){
      const seenAttributes = new Map(); // Map to store unique attribute keys
      const duplicateIndices:any  = []; // To store indices of duplicates in the required format
      const duplicates:any = [];
      const unique:any = [];
      console.log(orderProducts)
          // Iterate through each attribute in the group
          // orderProducts.forEach((a:any, attributeIndex:any) => {
          //    let attribute = a.attribute;
          //     // Create a unique key based on companyProductCode, qty, companyId, and unitId
          //     const attributeKey = `${attribute.companyProductCode}-${attribute.qty}-${attribute.companyId}-${attribute.unitId}`;
          //     // Check if the attributeKey has been seen before or if the price is 0 or empty
          //     const priceIsInvalid = !(attribute.price > 0);

          //     // Check if the attributeKey has been seen before
          //     if (seenAttributes.has(attributeKey) || priceIsInvalid) {
          //       // If a duplicate is found, store it in the duplicates array
          //       duplicates.push(a);
          //       duplicateIndices.push({ attributeIndex });
          //     } else {
          //       // Otherwise, add to the seen attributes and store in the unique array
          //       seenAttributes.set(attributeKey, { attributeIndex });
          //       unique.push(a);
          //     }
          // });
          // const sortedOrderProducts = [...orderProducts].sort((a: any, b: any) => {
          //   const isADuplicate = isDuplicate(a.attributeIndex);
          //   const isBDuplicate = isDuplicate(b.attributeIndex);
          
          //   // Sort duplicates first, then non-duplicates
          //   if (isADuplicate && !isBDuplicate) return -1;
          //   if (!isADuplicate && isBDuplicate) return 1;
          //   return 0;
          // });
         // First pass: count occurrences of each product
          orderProducts.forEach((product:any) => {
            const attribute = product.attribute;
            const attributeKey = `${attribute.companyProductCode}-${attribute.qty}-${attribute.companyId}-${attribute.unitId}`;
            
            if (seenAttributes.has(attributeKey)) {
                seenAttributes.set(attributeKey, seenAttributes.get(attributeKey) + 1);
            } else {
                seenAttributes.set(attributeKey, 1);
            }
          });

          // Second pass: collect only duplicates (those that appear more than once) and unique ones
          orderProducts.forEach((product:any) => {
            const attribute = product.attribute;
            const attributeKey = `${attribute.companyProductCode}-${attribute.qty}-${attribute.companyId}-${attribute.unitId}`;
            const priceIsInvalid = !(attribute.price > 0);
            if (seenAttributes.get(attributeKey) > 1 || priceIsInvalid) {
                duplicates.push(product);
            } else {
                unique.push(product);
            }
          });
          setVisibleProducts(25);
          if(duplicates && duplicates.length > 0){
            let newDataSortDuplicates = duplicates.sort((a:any, b:any) => a.attribute.id - b.attribute.id)
            const sortedOrderProducts = [...newDataSortDuplicates, ...unique];
            setOrderProducts((prev: any) => [...sortedOrderProducts]);
          }
         

console.log("Duplicate Indices:", duplicates);
      setDuplicate([...duplicates]);
      if(duplicates && duplicates.length === 0){
      setErrorMessage("")
      let payload:any = {};
      if(orderId){
        payload = {
          "quotation": {
            "dateOfEntry":dateOfEntry ? dateOfEntry.valueOf() : "",
            "orderDate":orderDate ? orderDate.valueOf() : "",
            "enquiryDate":enquiryDate ? enquiryDate.valueOf() : "",
            "sendingDate" : sendingDate ? sendingDate.valueOf() : "",
            "enquiryEmail":enquiryEmail ? enquiryEmail : "",
            "enquiryMobileNo":enquiryMobileNo ? enquiryMobileNo : "",
            "termsAndConditions":termsAndConditions ? termsAndConditions :"",
            "status":status,
            "orderStatus":orderStatus,
            "location":location,
            "totalQty": orderProducts.reduce((total: any, product: any) => {
              const quotationQty =  Number(product.quotationQty);
              return Number(total) + quotationQty;
          }, 0),
            "totalPrice": orderProducts.reduce((total:any, product:any) => Number(total) + 
            (Number(product.attribute.totalPrice)), 0),
            "withGst":withGst,
            "userId":selectedUserId.id
          },
          "updateQuotationProducts": orderProducts.filter((e:any) => e.attribute.quotationId &&
           e.attribute.quotationId > 0 && e.attribute.isUpdate).map((product:any) => ({
            "rowId":product.attribute.id,
            "quotationId":product.attribute.quotationId,
            "productId": product.attribute.productId,
            "companyId": product.attribute.companyId,
            "companyProductCode": product.attribute.companyProductCode,
            "qty": product.attribute.qty,
            "quotationQty": product.quotationQty,
            "unitId":product.attribute.unitId,
            "price": product.attribute.price,
            "landingPrice":product.attribute.landingPrice,
            "discount": product.attribute.discount,
            "discountValue": product.attribute.discountValue,
            "discountAmount": product.attribute.discountAmount,
            "gst_percentage": product.attribute.gst_percentage,
            "gstValue": product.attribute.gstValue,
            "gstAmount": product.attribute.gstAmount,
            "totalPrice": product.attribute.totalPrice,
          })),
          "createQuotationProducts": orderProducts.filter((e:any) => !e.attribute.quotationId ).map((product:any) => ({
           "rowId":product.attribute.quotationId,
           "quotationId":product.attribute.id,
           "productId": product.attribute.productId,
           "companyId": product.attribute.companyId,
           "companyProductCode": product.attribute.companyProductCode,
           "qty": product.attribute.qty,
           "quotationQty": product.quotationQty,
           "unitId":product.attribute.unitId,
           "price": product.attribute.price,
           "landingPrice": product.attribute.landingPrice,
           "discount": product.attribute.discount,
           "discountValue": product.attribute.discountValue,
           "discountAmount": product.attribute.discountAmount,
           "gst_percentage": product.attribute.gst_percentage,
           "gstValue": product.attribute.gstValue,
           "gstAmount": product.attribute.gstAmount,
           "totalPrice": product.attribute.totalPrice,
         }))
        };
      }else{
        payload = {
          "quotation": {
            "dateOfEntry":dateOfEntry ? dateOfEntry.valueOf() : "",
            "orderDate":orderDate ? orderDate.valueOf() : "",
            "enquiryDate":enquiryDate ? enquiryDate.valueOf() : "",
            "sendingDate" : sendingDate ? sendingDate.valueOf() : "",
            "enquiryEmail":enquiryEmail ? enquiryEmail : "",
            "enquiryMobileNo":enquiryMobileNo ? enquiryMobileNo : "",
            "termsAndConditions":termsAndConditions ? termsAndConditions :"",
            "status":status,
            "orderStatus":orderStatus,
            "location":location,
            "totalQty": orderProducts.reduce((total: any, product: any) => {
              const quotationQty =  Number(product.quotationQty);
              return Number(total) + quotationQty;
          }, 0),
            "totalPrice": orderProducts.reduce((total:any, product:any) => Number(total) + 
            (Number(product.attribute.totalPrice)), 0),
            "withGst":withGst,
            "userId":selectedUserId.id
          },
          "quotationProducts": orderProducts.map((product:any) => ({
            "quotationId":product.attribute.id,
            "productId": product.attribute.productId,
            "companyId": product.attribute.companyId,
            "companyProductCode": product.attribute.companyProductCode,
            "qty": product.attribute.qty,
            "quotationQty": product.quotationQty,
            "unitId":product.attribute.unitId,
            "price": product.attribute.price,
            "landingPrice" : product.attribute.landingPrice,
            "discount": product.attribute.discount,
            "discountValue": product.attribute.discountValue,
            "discountAmount": product.attribute.discountAmount,
            "gst_percentage": product.attribute.gst_percentage,
            "gstValue": product.attribute.gstValue,
            "gstAmount": product.attribute.gstAmount,
            "totalPrice": product.attribute.totalPrice,
          }))
  
        };
      }
     

      console.log(orderProducts , payload )
      if(orderId){
        // await update_order(payload,orderId);
        setLoading(false);
        window.location.href = WEB_BASE_URL+"admin/quotation_list"
        // navigate('/admin/quotation_list');
      }else{

        let chunkedProductArray = Array.from(
          { length: Math.ceil(payload.quotationProducts.length / insertQuotationAttriCount) },
          (_, i) => payload.quotationProducts.slice(i * insertQuotationAttriCount, i * insertQuotationAttriCount + insertQuotationAttriCount)
      );
      let quotationIdd = "";
      let index = 0;
      for (const qutatation of chunkedProductArray) {
        if (index === 0) {
          let payloadInsertBatchWise = {
            quotation: payload.quotation,
            quotationProducts:qutatation
          };
          const result: any = await create_order(payloadInsertBatchWise);
          console.log(result?.data, result?.data.quotationId);
          quotationIdd = result?.data?.quotationId;
        } else {
          console.log("quotationId", quotationIdd);
          if (quotationIdd && Number(quotationIdd) > 0) {
            let payloadInsertBatchWise = {
              userId:payload.quotation.userId,
              quotationProducts:qutatation
            }
            await insert_batch_quotation(payloadInsertBatchWise, quotationIdd);
          }
        }
        index++;
      }
      
        // await create_order(payload);
        setLoading(false);
        navigate('/admin/quotation_list');
      }
        
       
       setOrderProducts([])
       
       
    }else{
      setLoading(false);
      setErrorMessage("Please avoid adding duplicate products,  and ensure that the price is not empty or zero.")
    }
    }else{
      setLoading(false);
        setErrorMessage("Please select user,  Date of Entry, Order Date, Enquiry Date, Enquiry Mobile No, Enquiry Email and Product for create quotation.")
    }
    
  }

  
  // Handle file upload
  const handleFileUpload = async (event:any) => {
    setExcelLoading(true)
    const file = event.target.files[0];
    const expectedColumns = ["name", "companyProductCode", "unit", "qty","CompanyName", "discount", "gst_percentage"];
    if (file) {
     const reader = new FileReader();
      reader.onload = async (e:any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        // Validate column names
        const headerRow:any = worksheet[0]; // Get the header row
        const isValidHeader = expectedColumns.every((col, index) => headerRow[index] === col) && 
        expectedColumns.length === headerRow.length;
        if (!isValidHeader) {
          setExcelLoading(false);
          setErrorMessage("Invalid file format. Please upload a file with the correct column names and it's sequence needs to match. Please check sample file.");
          return; // Stop further processing if the column names don't match
        }else{
          setErrorMessage("");
        }
        let isValidData = false;
        // Convert rows
        const parsedRows:any = worksheet.slice(1).map((row:any) =>
          {
            if(!row[0]  || !(row[3] && row[3] > 0) || !row[2] || !row[2]){
              isValidData= true;
            }
            return {
              name: row[0],
              companyProductCode: row[1] ? row[1] : "",
              unit: row[2],
              qty: row[3],
              company_name:row[4],
              discount:row[5],
              gst_percentage:row[6]
            }}   );
            if(isValidData){
              setExcelLoading(false);
              setErrorMessage("Invalid file data. Please upload a file with the correct values of name, companyProductCode, unit ,price.");
              return;
            }

        console.log("parsedRows",parsedRows)
        setErrorMessage("")
        let chunkedProductArray = Array.from(
          { length: Math.ceil(parsedRows.length / insertQuotationAttriFetchCount) },
          (_, i) => parsedRows.slice(i * insertQuotationAttriFetchCount, i * insertQuotationAttriFetchCount + insertQuotationAttriFetchCount)
      );
      let outputdata:any = [];
      let notExitsData:any = [];
       for (const qutatation of chunkedProductArray) {
            let payload = {
              data:qutatation
            }
           const newData:any =   await fetch_batch_product(payload);  
           if(newData && newData.data?.data && newData.data?.data.length > 0){
            console.log(newData.data?.data)
            let finalData = newData.data?.data.filter((e:any)=> !e.attribute.isNotExits)
            let notExitsFinalData = newData.data?.data.filter((e:any)=> e.attribute.isNotExits)
            outputdata = [...outputdata,...finalData];
            notExitsData = [...notExitsData,...notExitsFinalData]
           }
         }
        console.log(outputdata) 
        
        setOrderProducts([...outputdata]);
        setNotExitsOrderData([...notExitsData]);
     setExcelLoading(false);
      };
      reader.readAsArrayBuffer(file);
    }
   
  };
  
 // Function to check if a given pair exists in duplicateIndices
 function isDuplicate(product:any) {
      return duplicates.some(
          (duplicate:any) =>  duplicate.attribute.id === product.attribute.id
      );
    }
const handleLoadMore = (isReset:boolean = false) => {
  if(isReset){
    setVisibleProducts(25);
  }else{
    setVisibleProducts(prevCount => prevCount + 25);
  }
};
console.log("companyId",companyId)
  return (
    <Container>
      <CustomizedSnackbars
      message = {"Quotation created successfully."} 
      openSnackBars={openSnackBars} 
      setOpenSnackBars={setOpenSnackBars}
      />
      <FormDiv>
      <Typography>Create Quotation
      <a href={IMG_BASE_URL+"sampleQuotationImport.xlsx"}><label style={{fontSize: "13px",
    color: "#199bff",
    textDecorationLine: "underline",
    marginLeft: "10px",
    "cursor":"pointer"
    }}>Download Sample Example File</label></a>
        </Typography>  <br/>
      <div>
    
      <Grid container spacing={2}>
           <Grid item xs={12} sm={12} md={2}>
           <Button variant="contained" component="label" disabled={loading}>
        Upload File
        <input type="file" hidden onChange={handleFileUpload} accept=".xlsx, .xls" />
      </Button>
      {
        notExitsOrderData && notExitsOrderData.length > 0 && <IconButton aria-label="delete"  onClick={() => setOpenExitsModal(true)}>
        <WarningIcon />
      </IconButton>
      }
      

           </Grid>
           <Grid item xs={12} sm={12} md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}
          >
            <DatePicker
                label={
                  <>
                  Date of Entry
                  <span style={{color:"red"}}>*</span>
                  </>
                } 
                value={dateOfEntry}
                onChange={(newValue) => setDateOfEntry(newValue)}
                slotProps={{ textField: { size: "small" } }}
                
              />
            </LocalizationProvider>  
          
           </Grid>
           <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={
                      <>
                       Order Date
                      <span style={{color:"red"}}>*</span>
                      </>
                    }
                    value={orderDate}
                    onChange={(newValue) => setOrderDate(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                  />
              </LocalizationProvider>  
           </Grid>
           <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                
                    label={
                      <>
                       Enquiry Date
                      <span style={{color:"red"}}>*</span>
                      </>
                    }
                    value={enquiryDate}
                    onChange={(newValue) => setEnquiryDate(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                  />
              </LocalizationProvider>  
           </Grid>
           <Grid item xs={12} sm={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={
                      <>
                       Sending Date
                       </>
                    }
                    value={sendingDate}
                    onChange={(newValue) => setSendingDate(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                  />
              </LocalizationProvider>  
           </Grid>
           
           <Grid item xs={12} sm={12} md={2}>
           <TextField
            label={<>
              Enquiry Mobile No
              <span style={{color:"red"}}>*</span>
              </>}
              InputLabelProps={{
                shrink: enquiryMobileNo ?  true : false, // Ensure label stays above the input when it has a value
              }}
              id="EnquiryMobileNo"
              type="number"
              size="small"
              value={enquiryMobileNo}
              onChange={(e:any) => setEnquiryMobileNo(e.target.value)}
              variant="outlined"
              
            />
         
           </Grid>
           <Grid item xs={12} sm={12} md={3}>
            <TextField
              style={{width:"100%"}}
              label={<>
                Enquiry Email
                <span style={{color:"red"}}>*</span>
                </>}
                  InputLabelProps={{
                    shrink: enquiryEmail ?  true : false, // Ensure label stays above the input when it has a value
                  }}
              id="enquiryEmail"
              type="text"
              size="small"
              value={enquiryEmail}
              onChange={(e:any)=> setEnquiryEmail(e.target.value)}
              variant="outlined"
            />
           </Grid>
           <Grid item xs={12} sm={12} md={9}>
           <UserSearch 
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId} />
              
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
            <Quilleditor value ={termsAndConditions} setValue={setTermsAndConditions} />
            {/* <FormControl fullWidth size="small">
                <TextField
                  id="outlined-multiline-static"
                  label="Terms & Conditions"
                  multiline
                  InputLabelProps={{
                    shrink: location ?  true : false, // Ensure label stays above the input when it has a value
                  }}
                  rows={4}
                  value={termsAndConditions}
                  onChange={(e)=>{
                    setTermsAndConditions(e.target.value)
                  }}
                />
                </FormControl> */}
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth size="small">
                <TextField
                  id="outlined-multiline-static"
                  label="Location"
                  InputLabelProps={{
                    shrink: location ?  true : false, // Ensure label stays above the input when it has a value
                  }}
                  multiline
                  rows={4}
                  value={location}
                  onChange={(e)=>{
                    setLocation(e.target.value)
                  }}
                />
                </FormControl>
              </Grid>

      </Grid> 

      </div>
      <br/>
     
        <Grid container spacing={2}>
        <Grid item sm={12} md={1}>
           <FormControl fullWidth size="small">
                  <InputLabel>
                  Company 
                  </InputLabel>
                  <Select
                    name="companyId"
                    label="Company *"
                    value={companyId}
                    onChange={(e:any) => setCompanyId(e.target.value)}
                    fullWidth
                  >
                     <MenuItem value={"1000000000"} key={"companies"+3355555}>None</MenuItem>
                    {
                      companies && companies.length > 0 && (
                        companies.map((e:any,index:number)=>{
                          return (
                            <MenuItem value={e.id} key={"companies"+index}>{e.name}</MenuItem>
                          )
                        })
                      )
                    }
                   
                  </Select>
                </FormControl>
           </Grid>
           <Grid item sm={12} md={11}>
             <ProductSearch onSelectToOrder={onSelectToOrder} 
             orderProducts={orderProducts}
             setOrderProducts={setOrderProducts} 
             units={units} 
             withGst={withGst}
             companyId={companyId} />
              
           </Grid>
          
           </Grid>
           <Grid container spacing={2}>
           <Grid item sm={12} md={12} lg={12}>
            {
              excelLoading && <>
                 <Box display="flex" justifyContent="center" alignItems="center" style={{height:"250px"}}>
                   {/* <CircularProgress />  */}
                   Loading...
                </Box>
              </>
            }
             <div style={orderProducts && orderProducts.length > 0  ? 
             {width:"100%",height:"600px",overflowY:"auto"} : {}}>
  
             {
              orderProducts && orderProducts.length > 0  ? 
              orderProducts.slice(0, visibleProducts).map((e: any, index: number) => {
                return <OrderProductBox key={`orderProductBox${index}`} product={e} 
                onQtyChange={updatequotationQty}
                deleteOrderedProduct={deleteOrderedProduct} units={units}
                onUnitChange={onUnitChange} gstRates={gstRates}   onGstChange={onGstChange}
                onUnitQtyChange={onUnitQtyChange}
                onPriceChange={onPriceChange}
                onDiscountChange={onDiscountChange}
                isDuplicate={isDuplicate}
                index={index}
                orderId={orderId}
                 />
              })
              :null
             }

             </div>
            {/* Load More Button */}
            <br/>
            {orderProducts && orderProducts.length > visibleProducts && (
              <>
              
              <Button variant="outlined"  onClick={() => handleLoadMore(false)} size={"small"} > Load More Products</Button>
              
              <Button variant="text"  onClick={() => handleLoadMore(true)} size={"small"} style={{color:"#9c0031"}} > Reset</Button> 
              <label>{ orderProducts.length -  orderProducts.slice(0, visibleProducts).length } Products remaining to shown</label>
              </>
            )}
            </Grid>
            <Grid item sm={3} md={6}> </Grid>
          
           <Grid item  sm={12} md={3}>
            <Box sx={{textAlign:"right",marginBottom:"10px"}}>
            <FormControl fullWidth
            
            >
               <InputLabel>
               Status
              </InputLabel>
              <Select
              size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={(e)=> setStatus(e.target.value)}
              >
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Processing"}>Processing</MenuItem>
                <MenuItem value={"Shipped"}>Shipped</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl>
            </Box>
           
            </Grid>
            <Grid item  sm={12} md={3}>
            <Box sx={{textAlign:"right",marginBottom:"10px"}}>
            <FormControl fullWidth
            
            >
               <InputLabel>
               Order Status
              </InputLabel>
              <Select
              size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={orderStatus}
                label="Order Status"
                onChange={(e)=> setOrderStatus(e.target.value)}
              >
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Granted"}>Granted</MenuItem>
              </Select>
            </FormControl>
            </Box>
           
            </Grid>
        </Grid>
       
          <Box sx={{textAlign:"right",marginBottom:"10px"}}>
            {
              orderProducts && orderProducts.length > 0 ? 
                <>
                <Typography variant="body1" sx={{marginRight:"20x"}} >
                Qty: <b>
                { orderProducts.reduce((total: any, product: any) => {
    const quotationQty = Number(product.quotationQty);
    return Number(total) + quotationQty;
}, 0) }
                  </b>
 &nbsp; &nbsp;
                  Total: <b>{(orderProducts.reduce((total:any, product:any) => Number(total) + 
                (Number(product.attribute.totalPrice)), 0) ).toFixed(2)}</b>
                              </Typography>
              
                </> : null
            }
            
          </Box>
            <Box sx={{textAlign:"center",marginBottom:"10px"}}>
              <label style={{color:"red"}}>{errorMessage}</label>
            </Box>

        <ButtonCust  sx={BUTTON_STYLE} onClick={createOrderFun}>
          Submit
        </ButtonCust> &nbsp;
        <ButtonCust  sx={CANCEL_BUTTON_STYLE} onClick={()=>{
           navigate('/admin/quotation_list');
        }}>
          Cancel
        </ButtonCust>
      </FormDiv>
      <DialogInvoice
      data={orderProducts} open={openInvoiceDailog} setOpen={setOpenInvoiceDailog}
      setOrderProducts={setOrderProducts}
      order={selectedUserId}
      />
      <Dialog
        open={openError}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Product qty is out of stock.
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseError}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <Modal
        title="Not Exits products list"
        centered
        open={openExitsModal}
        onOk={() => setOpenExitsModal(false)}
        onCancel={() => setOpenExitsModal(false)}
        width={800}
      >
       <NotExitsProductsModal productData={notExitsOrderData} />
      </Modal>
    </Container>
  );
};
export default OrderForm;
