import { styled } from '@mui/system';
import { Box, TextField, Button, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('https://static.vecteezy.com/system/resources/previews/000/952/735/non_2x/abstract-banner-background-vector.jpg');
  background-size: cover;
  background-position: center;
`;

export const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin:20px;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  backdrop-filter: blur(10px);
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 1.5rem;
  
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    }
    &:hover fieldset {
      border-color: #ffffff;
    }
    &.Mui-focused fieldset {
      border-color: #ffffff;
    }
  }
  
  & .MuiInputBase-input {
    color: #ffffff;
  }
  
  & .MuiInputLabel-root {
    color: #ffffff;
  }
`;

export const StyledButton = styled(Button)`

  background-color: #ffffff;
  color: #000000;
  &:hover {
    background-color: #f0f0f0;
  }
`;