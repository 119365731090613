
import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Rectangle,
  } from "recharts";


export default function TotalPricePerDay({data}:any) {
  return (
    data &&
    <div>
  <BarChart
    width={700}
    height={300}
    data={data}
    margin={{
      top: 5,
      right: 0,
      left: 0,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    {/* <XAxis dataKey="orderDayNumber" /> */}
    <XAxis dataKey="monthName" />
    <YAxis />
    <Tooltip />
    <Bar
      // dataKey="totalPrice"
      dataKey="totalQuotations"
      fill="#B3CDAD"
      activeBar={<Rectangle fill="pink" stroke="blue" />}
    />
  </BarChart>
    </div>
  
  );
}
