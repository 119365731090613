import React from 'react'
import { Col, Row, Typography } from 'antd'

interface TermsconditionsProps {
  style?: React.CSSProperties;
  selecteduitable: string;
  order:any;
}

const Termsconditions: React.FC<TermsconditionsProps> = ({ style, selecteduitable,order }) => {
  const styles = {
    ttitletex: {
      fontSize: '15px'
    },
    boldText: {
      fontWeight: 'bold',
      fontSize: '15px',
    },
  };

  return (
    <>
      <Typography.Title style={{
        textAlign: 'center',
        // backgroundColor: '#64713c',
        color: 'white',
        marginTop: '10px',
        fontSize: '15px',
        padding: '7px 0px',
        ...style,
      }}>TERMS & CONDITIONS</Typography.Title>
      <Row>
        <Col xs={12} sm={12} md={14} lg={14} xl={14}>
        {
          order.termsAndConditions ? 
          <div dangerouslySetInnerHTML={{ __html: order.termsAndConditions }} />
          :
          <>
          <Typography.Text>
            <span style={styles.boldText}>{`${1}}`}</span> <span style={styles.ttitletex}>Rates are Final.</span>
          </Typography.Text> <br />
          <Typography.Text>
            <span style={styles.boldText}>{`${2}}`}</span> <span style={styles.ttitletex}>GST will be Extra as per Government Rule.</span>
          </Typography.Text> <br />
          <Typography.Text>
            <span style={styles.boldText}>{`${3}}`}</span> <span style={styles.ttitletex}>Delivery at Your Site.</span>
          </Typography.Text> <br />
          <Typography.Text>
            <span style={styles.boldText}>{`${4}}`}</span> <span style={styles.ttitletex}>Delivery period 10 to 15 days.</span>
          </Typography.Text> <br />
          <Typography.Text>
            <span style={styles.boldText}>{`${5}}`}</span> <span style={styles.ttitletex}>Payment 50% Advance Along With Purchase Order. Remaining against delivery.</span>
          </Typography.Text>
          </>
        }
          
        </Col>
        <Col xs={12} sm={12} md={10} lg={10} xl={10}>
          <Typography.Text>Thanking You</Typography.Text> <br />
          <Typography.Text style={styles.boldText}>For {selecteduitable === "SOHAM FIRM" ? "Soham Scientific" : "Unique Biological & Chemicals"}</Typography.Text><br /><br /><br /><br /><br />
          <Typography.Text style={styles.boldText}>Kolhapur</Typography.Text><br />
        </Col>
      </Row>
    </>
  )
}

export default Termsconditions;
