import React, { useState } from 'react';
import { List, Collapse, Button, Typography, Row, Col, Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Text } = Typography;

const ProductListView = ({ alreadyAddedQuotationPro }: any) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const handleReset = () => {
    setVisibleItems(10);
  };

  const totalProducts = alreadyAddedQuotationPro?.length || 0;
  const remainingItems = totalProducts - visibleItems;
  const visibleData = alreadyAddedQuotationPro?.slice(0, visibleItems);

  return (
    alreadyAddedQuotationPro &&
    alreadyAddedQuotationPro.length > 0 && (
      <Collapse
        activeKey={isCollapsed ? '1' : undefined}
        style={{ margin: '0px' }}
        bordered
        expandIconPosition="end"
        ghost
      >
        <Panel
          key="1"
          header={
            <Button
              style={{ paddingLeft: '0px' }}
              type="link"
              onClick={toggleCollapse}
              icon={isCollapsed ? <UpOutlined /> : <DownOutlined />}
            >
              {isCollapsed
                ? 'Already Existing Product List'
                : 'Already Existing Product List'}
            </Button>
          }
        >
          {/* Header Row */}
          <Row gutter={16} style={{ width: '100%', marginBottom: '8px' }}>
            <Col span={7}>
              <Text strong>Name</Text>
            </Col>
            <Col span={3}>
              <Text>Code</Text>
            </Col>
            <Col span={3}>
              <Text>Company</Text>
            </Col>
            <Col span={2}>
              <Text>Qty</Text>
            </Col>
            <Col span={3}>
              <Text>Landing Price</Text>
            </Col>
            <Col span={2}>
              <Text>Price</Text>
            </Col>
            <Col span={2}>
              <Text>GST %</Text>
            </Col>
            <Col span={2}>
              <Text>Total</Text>
            </Col>
          </Row>

          <List
            bordered
            dataSource={visibleData}
            renderItem={(item: any) => (
              <List.Item style={{ padding: '8px 0' }}>
                <Row gutter={16} style={{ width: '100%' }}>
                  <Col span={7}>
                    <Text strong style={{paddingLeft:"10px"}}>{item.name}</Text>
                  </Col>
                  <Col span={3}>
                    <Text>{item.attribute.companyProductCode}</Text>
                  </Col>
                  <Col span={3}>
                    <Text>{item.attribute.companyName}</Text>
                  </Col>
                  <Col span={2}>
                    <Text>
                      {item.attribute.qty} {item.attribute.unitName}
                    </Text>
                  </Col>
                  <Col span={3}>
                    <Text>{item.attribute.landingPrice}</Text>
                  </Col>
                  <Col span={2}>
                    <Text>₹{item.attribute.price}</Text>
                  </Col>
                  <Col span={2}>
                    <Text>{item.attribute.gst_percentage}%</Text>
                  </Col>
                  <Col span={2}>
                    <Text>₹{item.attribute.totalPrice}</Text>
                  </Col>
                </Row>
              </List.Item>
            )}
          />

          {/* Load More and Reset Buttons */}
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>
              {visibleItems < totalProducts && (
                <Button type="primary" onClick={handleLoadMore}>
                  Load More ({remainingItems} more)
                </Button>
              )}
              {visibleItems > 10 && (
                <Button onClick={handleReset}>Reset</Button>
              )}
            </Space>
          </div>

          {/* Total Count Display */}
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Text type="secondary">
              Showing {Math.min(visibleItems, totalProducts)} of {totalProducts} products
            </Text>
          </div>
        </Panel>
      </Collapse>
    )
  );
};

export default ProductListView;
